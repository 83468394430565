<template>
  <div class="home">
    <!-- 顶部标题区域 -->
    <TopTitle ref="topTitle" :params="topTitleParams"></TopTitle>
    <!-- 首页类目 -->
    <!-- <IndexItem ref="indexItem" @select="selectEvent" @enter="handleEnterEvent"></IndexItem> -->
    <!-- 底部操作区域 -->
    <!-- <BottomOperate ref="bottomOperate" :params="bottomOperateParams"></BottomOperate> -->
    <!-- 地址管理组件 -->
    <Address ref="address" :params="addressParams"></Address>
    <!-- 商品组件 -->
    <Goods
      ref="goods"
      :params="goodsParams"
      :goods="productDetail.products"
      :productPrice="productDetail"
      @close-goods="onCloseGoods"
    ></Goods>
    <!-- 周边商品 -->
    <RimGoods ref="rimGoods" :params="rimGoodsParams"></RimGoods>
    <!-- 虚拟logo墙 -->
    <VirtualWall
      ref="virtualWall"
      :list="virtualWall.list"
      :url="virtualWall.currentLogoUrl"
      :info="virtualWall.detail"
      :params="virtualWallParams"
      @get-detail="getLogoDetail"
      @close="toCloseVirtual"
    ></VirtualWall>
    <!-- 横屏遮罩 -->
    <Message ref="message"></Message>
    <div id="container"></div>
    <div ref="operateRight" class="right-operate-content right">
      <div
        :class="['run', !isRun ? 'run-normal' : 'run-active']"
        @touchstart.stop="toRun"
        @touchend.stop="toRunStop"
      ></div>
    </div>
    <!-- 左侧控制 -->
    <div ref="operateLeft" class="left-operate-content">
      <div
        :class="['top', !isTop ? 'top-normal' : 'top-active']"
        @touchstart.stop="toTop"
        @touchend.stop="toTopStop"
      ></div>
      <div
        :class="['bottom', !isDown ? 'bottom-normal' : 'bottom-active']"
        @touchstart.stop="toDown"
        @touchend.stop="toDownStop"
      ></div>
      <div
        :class="['left', !isIn ? 'left-normal' : 'left-active']"
        @touchstart.stop="toIn"
        @touchend.stop="toInStop"
      ></div>
      <div
        :class="['right', !isOut ? 'right-normal' : 'right-active']"
        @touchstart.stop="toOut"
        @touchend.stop="toOutStop"
      ></div>
    </div>
    <!-- <span
      style="
        position: absolute;
        top: 10px;
        left: 20px;
        color: red;
        z-index: 999;
      "
      >{{ textName }}</span
    > -->
    <!-- 首页登录加载页 -->
    <!-- <IndexDashboard :params="indexDashParams"></IndexDashboard> -->
  </div>
</template>

<script>
import TopTitle from "@/components/TopTitleComponent";
// import IndexItem from '@/components/IndexItemComponent'
// import BottomOperate from '@/components/BottomOperateComponent'
import Address from "@/components/AddressComponent";
import Goods from "@/components/GoodsComponent";
import RimGoods from "@/components/RimGoodsComponent";
import VirtualWall from "@/components/VirtualWallComponent";
import Message from "@/components/MessageComponent";
import ModelLoader from "./load";

import IndexDashboard from "@/components/IndexDashboardComponent.vue";
import { getStorage } from "@/util";
import {
  getProductDetailById,
  getLogoDetailList,
  getLogoDetailInfo,
  getLogoList,
} from "@/api";

// 性能优化参数
let flag;
export default {
  name: "Home",
  components: {
    TopTitle,
    // IndexItem,
    // BottomOperate,
    Address,
    Goods,
    RimGoods,
    VirtualWall,
    Message,
    IndexDashboard,
  },
  data() {
    return {
      loadingTimer: null,
      topTitleParams: {
        pageType: "1",
        returnEvent: this.returnEvent,
        shopPlateformEvent: this.shopPlateformEvent,
        nightEvent: this.nightEvent,
        dayTimeEvent: this.dayTimeEvent,
      },
      bottomOperateParams: {
        directionEvent: this.directionEvent,
      },
      indexDashParams: {
        handleChangeEvent: this.handleIndexDashChangeEvent,
      },
      addressParams: {
        handleAddressClickEvent: this.handleAddressClickEvent,
      },
      goodsParams: {
        handleChangeEvent: this.handleChangeEvent,
      },
      rimGoodsParams: {
        handleChangeEvent: this.handleRimGoodsChangeEvent,
      },
      virtualWallParams: {
        handleChangeEvent: this.handleVirtualWallChangeEvent,
      },
      app: null,
      showModel: false,
      isRun: false,
      // 是否第一人称
      isFirst: false,
      productDetail: {
        products: [],
        price: 0,
        stock: 0,
        sales: 0,
        store_name: "---",
        description: "---",
        author: "--",
      },
      textName: "",
      // logo广告墙
      virtualWall: {
        currentLogoUrl: "",
        list: [],
      },
      logoList: [],
      isTop: false,
      isDown: false,
      isIn: false,
      isOut: false,
    };
  },
  // computed: {
  //   showModel() {
  //     return this.topTitleParams.pageType === '2'
  //   }
  // },
  watch: {
    topTitleParams: {
      handler(val) {
        this.showModel = val.pageType === "2";
      },
      deep: true,
    },
  },
  created() {
    const _this = this;
    this.$bus.$on("select-product", (pid) => {
      // console.log(e)
      _this._getProductDetailById(pid);
      _this.shopPlateformEvent();
    });
    // 获取logo详情
    this.$bus.$on("select-logo", ({ id, url }) => {
      // console.log(url)
      _this.$set(this.virtualWall, "currentLogoUrl", url);
      _this.handleVirtualWallChangeEvent(id, url);
    });
  },
  mounted() {
    window.addEventListener("orientationchange", this.getOrientation, false);
    if (window.orientation == 180 || window.orientation == 0) {
      // console.log("竖屏状态！");
      this.$refs.message.isClose = false;
    }
    this._getLogoList();
  },
  beforeDestroy() {
    this.initApp();
    // 移除监听
    window.removeEventListener("resize", this.getOrientation, false);
    window.removeEventListener("orientationchange", this.getOrientation, false);
  },
  methods: {
    initScene() {
      this.$nextTick(() => {
        document.documentElement.style.fontSize = "37.5px";
        if (!this.app) {
          this.initApp();
          // this.getOrientation();
          this.handleEnterEvent();
          this.app = new ModelLoader(document.getElementById("container"));
          // 加载场馆
          this.initLoadModel(this.app, "./model/model.glb");
        }
      });
    },
    initApp() {
      this.app && this.app.clearScene();
      // 性能优化关键
      flag && cancelAnimationFrame(flag);
    },
    // 加载模型
    initLoadModel(app, src) {
      const _this = this;
      let resizeWindow = function () {
        app.resizeDisplayGL();
        _this.getOrientation();
      };

      let animate = function () {
        flag = requestAnimationFrame(animate);
        app.render();
      };
      app.initGL();
      app.resizeDisplayGL();
      app.initContent(src, _this.logoList);
      animate();
      // 声明 rayCaster 和 mouse 变量
      let rayCaster = new THREE.Raycaster();
      let mouse = new THREE.Vector2();
      window.addEventListener("resize", resizeWindow, false);
      document.getElementById("container").addEventListener(
        "touchstart",
        function (event) {
          event.preventDefault();
          // console.log(event.touches[0])
          // clientY
          mouse.x =
            ((event.touches[0].pageX -
              app.canvas.getBoundingClientRect().left) /
              app.canvas.offsetWidth) *
              2 -
            1;
          mouse.y =
            -(
              (event.touches[0].pageY -
                app.canvas.getBoundingClientRect().top) /
              app.canvas.offsetHeight
            ) *
              2 +
            1;
          //通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
          rayCaster.setFromCamera(mouse, app.camera);
          // 获取与raycaster射线相交的数组集合，其中的元素按照距离排序，越近的越靠前
          let intersects = rayCaster.intersectObjects(app.scene.children, true);
          // console.log(intersects)
          _this.textName = intersects[0].object.name;
          if (intersects.length > 0) {
            console.log(intersects[0].object.name);
            console.log(intersects[0].point);
            // _this.textName = intersects[0].object.name;
            app.onClickPlene(intersects[0].object.name);
          }
        },
        false
      );
    },
    toRun(e) {
      if (this.app) {
        // wx.startRecord();
        e.stopPropagation();
        e.preventDefault();
        this.isRun = true;
        this.app.toRun();
      }
    },
    toRunStop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isRun = false;
      this.app.toRunStop();
    },
    toTop(e) {
      if (this.app) {
        e.stopPropagation();
        e.preventDefault();
        this.isTop = true;
        this.app.toWalk("top");
      }
    },
    toTopStop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isTop = false;
      this.app.toWalkStop();
    },
    toDown(e) {
      if (this.app) {
        e.stopPropagation();
        e.preventDefault();
        this.isDown = true;
        this.app.toWalk("down");
      }
    },
    toDownStop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isDown = false;
      this.app.toWalkStop();
    },
    toIn(e) {
      if (this.app) {
        e.stopPropagation();
        e.preventDefault();
        this.isIn = true;
        this.app.toWalk("in");
      }
    },
    toInStop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isIn = false;
      this.app.toWalkStop();
    },
    toOut(e) {
      if (this.app) {
        e.stopPropagation();
        e.preventDefault();
        this.isOut = true;
        this.app.toWalk("out");
      }
    },
    toOutStop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isOut = false;
      this.app.toWalkStop();
    },
    getOrientation() {
      if (window.orientation == 180 || window.orientation == 0) {
        // console.log("竖屏状态！");
        this.$refs.message.isClose = false;
        // this.initApp();
      } else {
        this.$refs.message.isClose = true;
        document.documentElement.style.fontSize = "37.5px";
        this.initScene();
      }
    },
    selectEvent() {
      this.topTitleParams.pageType = "1";
    },
    handleEnterEvent() {
      // console.log('enter')
      this.topTitleParams.pageType = "2";
      // this.$refs.bottomOperate.isClose = false
      // this.$refs.indexItem.isClose = true
    },
    handleIndexDashChangeEvent(flag) {
      if (flag === "page") {
        this.$refs.topTitle.isClose = false;
        // this.$refs.indexItem.isClose = false
        this.topTitleParams.pageType = "1";
        // console.log('加载完毕')
        this.app && this.app.loadOtherModel()
      }
    },
    returnEvent() {
      this.topTitleParams.pageType = "1";
      // this.$refs.bottomOperate.isClose = true
      // this.$refs.indexItem.activeIndex = 0
      // this.$refs.indexItem.loadingParams.persent = 0
      // this.$refs.indexItem.isClose = false
    },
    // 夜晚
    nightEvent() {
      // this.$refs.bottomOperate.senceModel = 'night'
      // console.log("nightEvent");
      this.app && this.app.switchCubeBackground(2);
    },
    // 白天
    dayTimeEvent() {
      // this.$refs.bottomOperate.senceModel = 'light'
      // console.log("dayTimeEvent");
      this.app && this.app.switchCubeBackground(1);
    },
    shopPlateformEvent() {
      // console.log("shopPlateformEvent");
      this.$refs.topTitle.isClose = true;
      this.$refs.goods.isClose = false;
    },
    handleAddressClickEvent(flag) {
      if (flag === "selectAddress") {
        console.log(getStorage("address"));
        this.$refs.address.isClose = true;
        this.$refs.goods.isClose = false;
      } else if (flag === "close") {
        this.$refs.goods.isClose = false;
      }
    },
    handleChangeEvent(flag) {
      if (flag === "changeAddress") {
        this.$refs.address.isClose = false;
        this.$refs.goods.isClose = true;
      } else if (flag === "around") {
        this.$refs.rimGoods.isClose = false;
        this.$refs.goods.isClose = true;
      } else if (flag === "close") {
        this.$refs.goods.isClose = true;
        this.$refs.topTitle.isClose = false;
      }
      console.log("selectAdressEvent" + flag);
    },
    handleRimGoodsChangeEvent(flag) {
      if (flag === "close") {
        // console.log(flag);
        this.$refs.rimGoods.isClose = true;
        this.$refs.goods.isClose = false;
      }
    },
    handleVirtualWallChangeEvent(id) {
      this._getLogoDetailList(id);
    },
    directionEvent(direction) {
      console.log(direction);
    },
    // 点击画作获取画的详情
    _getProductDetailById(id) {
      getProductDetailById({ id }).then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          this.products = res.data.slider_image;
          this.$set(this.productDetail, "products", res.data.slider_image);
          this.$set(this.productDetail, "store_name", res.data.store_name);
          this.$set(this.productDetail, "price", Number(res.data.price));
          this.$set(this.productDetail, "stock", res.data.stock);
          this.$set(this.productDetail, "sales", res.data.sales);
          this.$set(this.productDetail, "author", res.data.author);
          this.$set(
            this.productDetail,
            "description",
            res.data.description || "暂无内容"
          );
        }
      });
    },
    onCloseGoods () {
      this.products = []
      this.$set(this.productDetail, "products", []);
      this.$set(this.productDetail, "store_name", '--');
      this.$set(this.productDetail, "price", 0.0);
      this.$set(this.productDetail, "stock", '');
      this.$set(this.productDetail, "sales", '');
      this.$set(this.productDetail, "author", '');
      this.$set(
        this.productDetail,
        "description",
        "暂无内容"
      );
    },
    // 点击logo获取左侧展画列表
    _getLogoDetailList(id) {
      getLogoDetailList({ id }).then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          this.$set(this.virtualWall, "list", res.data);
          if (res.data.length > 0) {
            this._getLogoDetailInfo(res.data[0].id);
          }
          this.$refs.topTitle.isClose = true;
          this.$refs.virtualWall.isClose = false;
        }
      });
    },
    getLogoDetail(id) {
      this._getLogoDetailInfo(id);
    },
    _getLogoDetailInfo(id) {
      getLogoDetailInfo({ id }).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          // console.log(res.data);
          this.$set(this.virtualWall, "detail", res.data);
        }
      });
    },
    toCloseVirtual() {
      this.$set(this.virtualWall, "list", []);
      this.$set(this.virtualWall, "detail", {});
      this.$refs.topTitle.isClose = false;
      this.$refs.virtualWall.isClose = true;
    },
    _getLogoList() {
      getLogoList().then((res) => {
        // console.log(res)
        if (res.status === 200) {
          this.logoList = res.data;
          this.initScene();
          // document.documentElement.style.fontSize = "37.5px";
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  position: relative;
  width: 100%;
  height: 100%;
  background: #3b466dff;
}
#container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.right-operate-content {
  min-width: 80px;
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  .run {
    position: relative;
    top: -30px;
    left: -10px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    // pointer-events: none;
  }
  .run-normal {
    background-image: url("../assets/img/run1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .run-active {
    background-image: url("../assets/img/run1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .bottom {
    position: absolute;
    // bottom: 50px;
    // top: 0;
    bottom: 10px;
    right: 25px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .bottom-normal {
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .bottom-active {
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .left {
    position: absolute;
    // bottom: 50px;
    // top: 0;
    top: -20px;
    left: 80px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .left-normal {
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .left-active {
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .right {
    position: absolute;
    // bottom: 50px;
    // top: 0;
    top: -20px;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .right-normal {
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .right-active {
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .circle {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    right: 80px;
    top: -10px;
    background-image: url("../assets/img/bim.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;
  }
  .circle:hover,
  .bim {
    background-image: url("../assets/img/bim.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .close-circle {
    width: 40px;
    position: relative;
    margin-right: -87px;
    margin-bottom: 80px;
  }
}
.left-operate-content {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  left: 56px;
  z-index: 99;
  //  background-color: rgba(0, 0, 0, 0.4);
  .top {
    position: relative;
    // top: -50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url("../assets/operate/top.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .top-normal {
    background-image: url("../assets/operate/top.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .top-active {
    background-image: url("../assets/operate/top.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .bottom {
    position: relative;
    // bottom: 50px;
    top: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .bottom-normal {
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .bottom-active {
    background-image: url("../assets/operate/down.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .left {
    position: absolute;
    // bottom: 50px;
    // top: 0;
    top: 35px;
    left: -50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .left-normal {
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .left-active {
    background-image: url("../assets/operate/left.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .right {
    position: absolute;
    // bottom: 50px;
    // top: 0;
    top: 35px;
    right: -50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .right-normal {
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .right-active {
    background-image: url("../assets/operate/right.png");
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
