import Bus from "@/util/bus.js";
import { getNaturalWidth } from "@/util";
import MeshoptDecoder from '@/conf/meshopt_decoder.js'
import {
  LGGO_BORAD,
  LOGO_BOARDS_2,
  GLOD_BORAD_OUT,
  GLOD_BORAD_IN,
  JUDGE_BORAD_OUT,
  JUDGE_BORAD_IN,
  COPPER_BORAD_OUT,
  SLIVER_BORAD_OUT,
  SLIVER_BORAD_IN,
  COPPER_BORAD_IN,
  BOARD_IN_1,
  BOARD_IN_2,
  BOARD_IN_3,
  BOARD_IN_4,
  BOARD_IN_5,
  BOARD_IN_6,
  BOARD_IN_7,
  BOARD_IN_8,
  BOARD_IN_9,
  BOARD_IN_10
} from "@/conf/scene.js";
function ModelLoader(elementToBindTo) {
  this.renderer = null;
  this.canvas = elementToBindTo;
  this.aspectRatio = 1;
  this.group = null;
  this.meshList = [];
  this.renderer2D = null;
  this.renderEnabled = false;
  this.timeOut = null;
  this.recalcAspectRatio();
  this.scene = null;
  this.dop = new Dop();
  // console.log(this.dop)
  this.cameraDefaults = {
    posCamera: new THREE.Vector3(-400.0, -100, 18500.0),
    posCameraTarget: new THREE.Vector3(0, 0, 0),
    near: 0.01,
    far: 2000000,
    fov: 45,
  };
  this.camera = null;
  this.cameraTarget = new THREE.Vector3(-400.0, -100, 18500.0);
  this.controls = null;
  this.mixer = null;
  this.logoMixer = null;
  this.logoAnimation = [];
  this.logoActions = null;
  this.naruto = null; // 角色移动
  this.clock = new THREE.Clock();
  this.position = new THREE.Vector3();
  this.move = new THREE.Vector3(); //当前位置移动的距离
  this.actions = []; //所有的动画数组
  this.narutoDir = null; // 角色方向
  this.dirMove = new THREE.Vector3(); //当前位置移动的距离
  this.touchDirectionType = null;
  this.loadingManager = null;
  this.setup = { forward: 0, turn: 0 };
  this.followCamera = new THREE.Object3D();
  this.progress = 0;
  this.curve = null;
  // 圆盘控制参数
  this.controlParams = {
    down: false,
  };
  // 物理世界
  this.pWorld = null;
  this.oldElapsedTime = 0;
  this.snpF = 1.0;
  this.target = null;
  this.offestY = 300;
  this.touchRun = false;
  this.labelRenderer = null;
  this.labelContents = [];
  this.offestZ = -500;
  this.room = null;
  this.raycasterFront = new THREE.Raycaster();
  // 墙壁mesh
  this.qBMesh = null;
  this.labelCSS3Render = null;
  // 原始关键面材质
  this.originMeri = {};
  this.originMeriMap = {};
  this.walkTowers = null;
  // 广告牌
  this.windowGeometry = null;
  // 展华轨道
  this.arc1 = null;
  this.arc2 = null;
  this.arc3 = null;
}

/**
 * 添加天空盒背景
 */
ModelLoader.prototype.getTexturesFromAtlasFile = function () {
  const imgUrl = [
    "./images/skybox/px.jpg",
    "./images/skybox/nx.jpg",
    "./images/skybox/py.jpg",
    "./images/skybox/ny.jpg",
    "./images/skybox/pz.jpg",
    "./images/skybox/nz.jpg",
  ];
  return new THREE.CubeTextureLoader().load(imgUrl);
};
ModelLoader.prototype.getTexturesLightFromAtlasFile = function () {
  const imgUrl = [
    "./images/skybox/right.jpg",
    "./images/skybox/left.jpg",
    "./images/skybox/top.jpg",
    "./images/skybox/down.jpg",
    "./images/skybox/back.jpg",
    "./images/skybox/front.jpg",
  ];
  return new THREE.CubeTextureLoader().load(imgUrl);
};
ModelLoader.prototype.switchCubeBackground = function (type) {
  const _this = this;
  let modelScene = this.scene.getObjectByName("SCENE_CG");
  // console.log(modelScene);
  if (type === 1) {
    // 白天
    this.scene.background = this.getTexturesLightFromAtlasFile();
    modelScene.traverse(function (child) {
      child.frustumCulled = true;
      child.receiveShadow = true;
      // child.material.envMap = _this.scene.background;
      child.frustumCulled = true;
      if (child instanceof THREE.Mesh) {
        {
          if (["Shell_2"].includes(child.name)) {
            //模型阴影
            child.material = new THREE.MeshPhysicalMaterial({
              map: _this.originMeriMap[child.name], //获取原来材质的颜色贴图属性值
              color: _this.originMeri[child.name],
              metalness: 0.7,
              roughness: 0.35,
              transparent: true,
              // reflectivity: 0.5,
              side: THREE.FrontSide,
            });
          } else if (["Mesh.008_0"].includes(child.name)) {
            child.material = new THREE.MeshPhysicalMaterial({
              map: _this.originMeriMap[child.name], //获取原来材质的颜色贴图属性值
              color: _this.originMeri[child.name],
              metalness: 0.7,
              roughness: 0.65,
              opacity: 0.78,
              transparent: true,
              reflectivity: 0.5,
              side: THREE.FrontSide,
            });
          } else if (["Piping"].includes(child.name)) {
            child.material = new THREE.MeshPhysicalMaterial({
              map: child.material.map, //获取原来材质的颜色贴图属性值
              color: 0xc0c0c0,
              metalness: 0.2,
              roughness: 0.25,
              side: THREE.FrontSide,
              transparent: true,
            });
          } else if (["Mesh.010_0", "Mesh.009_0"].includes(child.name)) {
            child.material = new THREE.MeshPhysicalMaterial({
              map: _this.originMeriMap[child.name], //获取原来材质的颜色贴图属性值
              color: _this.originMeri[child.name],
              shininess: 0,
              metalness: 0.8,
              roughness: 0.65,
              opacity: 1,
              side: THREE.FrontSide,
              transparent: true,
            });
          }
        }
      }
    });
  } else {
    // 黑夜
    this.scene.background = this.getTexturesFromAtlasFile();
    modelScene.traverse(function (child) {
      child.frustumCulled = true;
      child.receiveShadow = true;
      // child.material.envMap = _this.scene.background;
      child.frustumCulled = true;
      if (child instanceof THREE.Mesh) {
        if (
          [
            "Mesh.007_3",
            "Mesh.007_0",
            "Mesh.003_0",
            "Mesh.003_1",
            "Mesh.007_2",
            "Mesh.007_1",
            "Glass_3",
          ].includes(child.name)
        ) {
          //模型阴影
          child.material = new THREE.MeshStandardMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x3c4556, //读取原来材质的颜色
            opacity: 0.8,
            metalness: 0.35,
            roughness: 0.15,
            transparent: true,
            side: THREE.FrontSide,
          });
        } else if (["LED_Light_2", "LED_Light"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x64a0cc, //读取原来材质的颜色
            metalness: 0.5,
            roughness: 0.65,
            transparent: false,
            // reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Shell_2"].includes(child.name)) {
          //模型阴影
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0e, //读取原来材质的颜色
            metalness: 0.8,
            roughness: 0.65,
            transparent: true,
            reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Mesh.008_0"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0xb3b3b3, //读取原来材质的颜色
            metalness: 0.7,
            roughness: 0.65,
            opacity: 0.78,
            transparent: true,
            reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Piping"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0e,
            metalness: 1,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else if (["Mesh.010_0", "Mesh.009_0"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0f,
            metalness: 0.5,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else if (["Mesh.009_1", "Mesh.010_1"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x64a0cc,
            metalness: 0.5,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: child.material.color, //读取原来材质的颜色
            metalness: 1,
            roughness: 0.35,
            transparent: true,
            reflectivity: 1.5,
            side: THREE.FrontSide,
          });
        }
      }
    });
  }
};
ModelLoader.prototype.toRun = function (type) {
  this.touchRun = true;
};
ModelLoader.prototype.toRunStop = function () {
  this.touchRun = false;
};
ModelLoader.prototype.initGL = function () {
  const _this = this;
  this.renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    premultipliedAlpha: false, // true/false 表示是否可以设置像素深度（用来度量图像的分辨率）
    preserveDrawingBuffer: true, // true/false 表示是否保存绘图缓冲
    logarithmicDepthBuffer: true,
  });
  this.renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    premultipliedAlpha: false, // true/false 表示是否可以设置像素深度（用来度量图像的分辨率）
    preserveDrawingBuffer: true, // true/false 表示是否保存绘图缓冲
    logarithmicDepthBuffer: true,
  });
  this.renderer.sortObjects = false; // 解决模型闪烁问题
  this.renderer.setPixelRatio(window.devicePixelRatio);
  this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
  // this.renderer.setClearColor(0x000000, 0.0);
  this.renderer.shadowMap.enabled = true;
  this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  // this.renderer.setPixelRatio(window.devicePixelRatio / 2);//设置设备像素比率,防止Canvas画布输出模糊。
  this.renderer.outputEncoding = THREE.sRGBEncoding; //解决加载gltf格式模型纹理贴图和原图不一样问题
  this.canvas.appendChild(this.renderer.domElement);
  this.scene = new THREE.Scene();
  /**
   * 相机设置
   */
  //创建相机对象
  let width = this.canvas.clientWidth; //窗口宽度
  let height = this.canvas.clientHeight; //窗口高度
  let k = width / height; //窗口宽高比

  this.camera = new THREE.PerspectiveCamera(
    this.cameraDefaults.fov,
    k,
    this.cameraDefaults.near,
    this.cameraDefaults.far
  );
  // 默认Y轴朝上
  this.camera.up.set(0, 1, 0);
  this.camera.position.set(0, 800, -800);
  // 对于正投影相机，鼠标中键滚动的时候，鼠标中键会改变相机的zoom属性
  this.camera.zoom = 1;
  this.camera.updateProjectionMatrix(); //注意更新相机对象的投影矩阵
  this.camera.lookAt(new THREE.Vector3());
  this.controls = new THREE.OrbitControls(
    this.camera,
    document.getElementById("container")
  );
  this.controls.enableDamping = true;
  this.controls.dampingFactor = 0.15;
  this.controls.rotateSpeed = 0.5;
  this.controls.minPolarAngle = 1.4;
  // 场景加载进度
  this.loadingManager = new THREE.LoadingManager();
  this.loadingManager.onProgress = (url, loaded, total) => {
    // console.log({ loaded, total })
    Bus.$emit("load-model", { loaded, total });
  };
  this.loadingManager.onLoad = function () {
    // console.log("加载完毕");
    if (!_this.labelRenderer) {
      _this.labelRenderer = new THREE.CSS2DRenderer();
      _this.labelRenderer.setSize(
        _this.canvas.clientWidth,
        _this.canvas.clientHeight
      );
      _this.labelRenderer.domElement.style.position = "absolute";
      _this.labelRenderer.domElement.style.top = "0";
      _this.labelRenderer.domElement.style.pointerEvents = "none";
      document.body.appendChild(_this.labelRenderer.domElement);
    }
    if (!_this.labelCSS3Render) {
      // 创建一个CSS3渲染器CSS3DRenderer
      _this.labelCSS3Render = new THREE.CSS3DRenderer();
      _this.labelCSS3Render.setSize(
        _this.canvas.clientWidth,
        _this.canvas.clientHeight
      );
      _this.labelCSS3Render.domElement.style.position = "absolute";
      // 相对标签原位置位置偏移大小
      _this.labelCSS3Render.domElement.style.top = "0px";
      _this.labelCSS3Render.domElement.style.left = "0px";
      // //设置.pointerEvents=none，以免模型标签HTML元素遮挡鼠标选择场景模型
      _this.labelCSS3Render.domElement.style.pointerEvents = "none";
      document.body.appendChild(_this.labelCSS3Render.domElement);
    }
  };
  // this.initCannon();
  // 设置光源
  let ambientLight = new THREE.AmbientLight(0x292929, 0.3);
  this.directionalLight = new THREE.DirectionalLight(
    new THREE.Color(0xffffff),
    0.4
  );
  this.directionalLight.position.set(1, 500, 1).normalize();
  this.directionalLight.castShadow = true;
  this.directionalLight.shadow.camera.top = 2;
  this.directionalLight.shadow.camera.bottom = -2;
  this.directionalLight.shadow.camera.left = 20;
  this.directionalLight.shadow.camera.right = 20;
  this.directionalLight.shadow.camera.near = 0.1;
  this.directionalLight.shadow.camera.far = 40;
  this.directionalLight.lookAt(new THREE.Vector3());
  // 坐标辅助，调试时打开
  // let axesHelper = new THREE.AxesHelper(3000);
  // this.scene.add(axesHelper);
  // 添加灯光
  this.scene.add(this.directionalLight);
  this.scene.add(ambientLight);
};
// 创建物理世界
ModelLoader.prototype.initCannon = function () {
  this.pWorld = new CANNON.World();
  this.pWorld.broadphase = new CANNON.SAPBroadphase(this.pWorld); //更好性能，travelling too fast
  this.pWorld.allowSleep = true;
  // 在多个步骤的任意轴上测试刚体的碰撞
  this.pWorld.broadphase = new CANNON.SAPBroadphase(this.pWorld);
  // 设置物理世界的重力为沿y轴向上-10米每二次方秒
  this.pWorld.gravity.set(0, -10, 0);
  // 创建默认联系材质
  this.pWorld.defaultContactMaterial.friction = 0;
  const groundMaterial = new CANNON.Material("groundMaterial");
  const wheelMaterial = new CANNON.Material("wheelMaterial");
  const wheelGroundContactMaterial = new CANNON.ContactMaterial(
    wheelMaterial,
    groundMaterial,
    {
      // 摩擦系数
      friction: 0,
      // 恢复系数
      restitution: 0,
      // 接触刚度
      contactEquationStiffness: 1000,
    }
  );
  this.pWorld.addContactMaterial(wheelGroundContactMaterial);
  const defaultMaterial = new CANNON.Material("default");
  const defalutContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
      friction: 0.1,
      restitution: 0.4,
    }
  );
  this.pWorld.addContactMaterial(defalutContactMaterial);
  const planeShape = new CANNON.Plane();
  const planeBody = new CANNON.Body({
    mass: 0, //质量为0，表示该物体固定
    shape: planeShape,
    material: defaultMaterial,
  });
  planeBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(-1, 0, 0),
    Math.PI * 0.5
  );
  this.pWorld.addBody(planeBody);
};
ModelLoader.prototype.excEvent = function (el, event) {
  if (document.createEvent) {
    let e = document.createEvent("MouseEvents");
    el.dispatchEvent(e);
  } else if (el.fireEvent) {
    el.fireEvent("on" + event);
  }
};
ModelLoader.prototype.timeRender = function () {
  //设置为可渲染状态
  this.renderEnabled = true;
  //清除上次的延迟器
  if (this.timeOut) {
    clearTimeout(this.timeOut);
  }

  this.timeOut = setTimeout(function () {
    this.renderEnabled = false;
  }, 100);
};
ModelLoader.prototype.initExhibitionTag = function ({ point, image }) {
  let content = this.exhibitionTag({ image, scale: 1 });
  content.name = "PRO_" + image;
  // console.log(content);
  point = Object.assign(point);
  content.position.copy(point);
  this.scene.add(content);
};
/**
 * 创建展画标签
 * @param {*} image 图片地址
 * @returns
 */
ModelLoader.prototype.exhibitionTag = function ({ image, scale }) {
  // 创建div元素(作为标签)
  let div = document.createElement("div");
  div.classList.add("exhibition-main");
  div.classList.add("exhibition");
  div.innerHTML = '<img src="' + image + '" />';
  //div元素包装为CSS3模型对象CSS3DObject
  let label = new THREE.CSS3DObject(div);
  /**
   * 避免HTML标签遮挡三维场景的鼠标事件
   * @type {string}
   */
  div.style.pointerEvents = "none";
  /**
   * 根据相机渲染范围控制HTML 3D标签尺寸
   */
  label.scale.set(scale, scale, scale);
  // label.rotateX(-Math.PI/2);
  return label;
};
// 加载场景模型
ModelLoader.prototype.initModel = function (name, gltf, marker, logos) {
  const _this = this;
  let dracoLoader = new THREE.DRACOLoader();
  // console.log(logos)
  let gltfLoader = new THREE.GLTFLoader(this.loadingManager);
  // gltfLoader.setMeshoptDecoder(MeshoptDecoder)
  dracoLoader.setDecoderPath("./draco/");
  dracoLoader.setDecoderConfig({ type: "js" }); //使用兼容性强的draco_decoder.js解码器
  dracoLoader.preload();
  gltfLoader.setDRACOLoader(dracoLoader);
  const shelterGeometry = new THREE.BoxBufferGeometry(0.15, 2, 0.15);
  const shelterLocation = new THREE.Mesh(
    shelterGeometry,
    new THREE.MeshNormalMaterial({
      transparent: true,
      opacity: 0,
    })
  );
  shelterLocation.position.set(0, 0, 0);
  shelterLocation.rotateY(Math.PI);
  this.scene.add(shelterLocation);
  this.group = new THREE.Group();
  // let cubeCamera1 = new THREE.CubeCamera(0.1, 1000, 2048);
  // cubeCamera2 = new THREE.CubeCamera(0.1, 1000, 2048);
  let callbackOnLoad = function (object) {
    object.scene.name = "SCENE_" + name;
    object.scene.castShadow = true;
    object.scene.receiveShadow = true; // 允许接收阴影
    object.scene.scale.set(100, 100, 100);
    object.scene.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        if (
          [
            "Mesh.007_3",
            "Mesh.007_0",
            "Mesh.003_0",
            "Mesh.003_1",
            "Mesh.007_2",
            "Mesh.007_1",
            "Glass_3",
          ].includes(child.name)
        ) {
          //模型阴影
          child.material = new THREE.MeshStandardMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x3c4556, //读取原来材质的颜色
            opacity: 0.8,
            metalness: 0.35,
            roughness: 0.15,
            transparent: true,
            side: THREE.FrontSide,
          });
        } else if (["LED_Light_2", "LED_Light"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x64a0cc, //读取原来材质的颜色
            metalness: 0.5,
            roughness: 0.65,
            transparent: false,
            // reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Shell_2"].includes(child.name)) {
          //模型阴影
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0e, //读取原来材质的颜色
            metalness: 0.8,
            roughness: 0.65,
            transparent: true,
            reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Mesh.008_0"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0xb3b3b3, //读取原来材质的颜色
            metalness: 0.7,
            roughness: 0.65,
            opacity: 0.78,
            transparent: true,
            reflectivity: 0.5,
            side: THREE.FrontSide,
          });
        } else if (["Piping"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0e,
            metalness: 1,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else if (["Mesh.010_0", "Mesh.009_0"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x0b0c0f,
            metalness: 0.5,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else if (["Mesh.009_1", "Mesh.010_1"].includes(child.name)) {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: 0x64a0cc,
            metalness: 0.5,
            roughness: 0.65,
            opacity: 1,
            side: THREE.FrontSide,
            transparent: true,
          });
        } else {
          child.material = new THREE.MeshPhysicalMaterial({
            map: child.material.map, //获取原来材质的颜色贴图属性值
            color: child.material.color, //读取原来材质的颜色
            metalness: 1,
            roughness: 0.35,
            transparent: true,
            reflectivity: 1.5,
            side: THREE.FrontSide,
          });
        }
      } else if (child instanceof THREE.Group) {
        // console.log(child)
        child.traverse(function (gChild) {
          if (!(gChild instanceof THREE.Mesh)) return;
          // console.log(gChild.name)
          if (
            [
              "网格.029_0",
              "网格.029_1",
              "网格.029_2",
              "网格.031_0",
              "网格.031_1",
            ].includes(gChild.name)
          ) {
            gChild.material = new THREE.MeshStandardMaterial({
              map: gChild.material.map, //获取原来材质的颜色贴图属性值
              color: gChild.material.color, //读取原来材质的颜色
              opacity: 0.7,
              metalness: 0.35,
              roughness: 0.45,
              transparent: true,
              side: THREE.DoubleSide,
            });
          }
        });
      }
      // cubeCamera1.position.copy(child.position);
      _this.meshList.push(child);
    });
    shelterLocation.add(object.scene);
    _this.room = object.scene;
    // 加载人物
    _this.loadFbxModel("./model/man/Soldier.glb");

  };
  gltfLoader.load(gltf, callbackOnLoad, null, null, null, false);
  // 添加光源
  const shelterPointLight = new THREE.PointLight(
    new THREE.Color(0xffffff),
    0.14
  );
  // 0.23
  shelterPointLight.position.set(0, 850, 0);
  shelterLocation.add(shelterPointLight);
  const shelterLight = new THREE.DirectionalLight(
    new THREE.Color(0xffffff),
    0.56
  );
  // 0.4
  shelterLight.position.set(0, 50, 0);
  shelterLight.castShadow = true;
  shelterLight.target = shelterLocation;
  this.scene.add(shelterLight);
  let light = new THREE.HemisphereLight(0xbbbbff, 0x292929);
  light.position.set(0, 100, 0);
  this.scene.add(light);

  this.flagControls = false;
  // const hammertime = new Hammer(this.canvas);
  // // let sphericalDelta = new THREE.Spherical();
  // hammertime.on("panstart", function () {
  //   // console.log(ev);
  //   // _this.flagControls = true;
  // });
  // hammertime.on("panend", function () {
  //   // console.log(ev);
  //   _this.flagControls = false;
  // });
  // hammertime.on("panleft", function (ev) {
  //   // console.log('left', ev);
  //   let rotateAngle = ((Math.PI / 15) * Math.abs(ev.velocityX)) / 10;
  //   // console.log(rotateAngle)
  //   _this.target.rotateY(rotateAngle);
  //   _this.target.rotateOnAxis(new THREE.Vector3(0, 1, 0), rotateAngle);
  //   // _this.setCamera(rotateAngle);
  // });
  // hammertime.on("panright", function (ev) {
  //   let rotateAngle = ((Math.PI / 15) * Math.abs(ev.velocityX)) / 10;
  //   // console.log(rotateAngle)
  //   _this.target.rotateY(-rotateAngle);
  //   _this.target.rotateOnAxis(new THREE.Vector3(0, 1, 0), -rotateAngle);
  //   // _this.setCamera(-rotateAngle);
  // });
  // hammertime.on("panup", function (ev) {
  //   // console.log("up", ev.distance);
  //   let rotateAngle = ((Math.PI / 15) * Math.abs(ev.velocityY)) / 10;
  //   _this.rotateCamera(-rotateAngle);
  //   // _this.setCamera();
  // });
  // hammertime.on("pandown", function (ev) {
  //   // console.log(ev);
  //   let rotateAngle = ((Math.PI / 15) * Math.abs(ev.velocityY)) / 10;
  //   _this.rotateCamera(rotateAngle);
  //   // _this.setCamera();
  // });
};
// 设置画报
ModelLoader.prototype.setArtBoard = function ({
  id,
  width,
  height,
  length,
  image,
  position,
  rotation,
  marker,
  opacity,
  color,
  transparent,
}) {
  const group = new THREE.Group();
  const windowGeometry = new THREE.BoxGeometry(width, height, length);
  let floorTexture = null;
  if (image) {
    floorTexture = new THREE.TextureLoader().load(image);
    floorTexture.anisotropy = 1;
    floorTexture.minFilter = THREE.NearestFilter;
  }
  const windowMaterial = new THREE.MeshBasicMaterial({
    map: floorTexture,
    // 0xcfcfcf
    color: 0xffffff,
    metalness: 0.2,
    roughness: 0.8,
    opacity: opacity || 1,
    transparent: transparent || true,
    side: THREE.FrontSide,
    // envMapIntensity: 10,
    // premultipliedAlpha: true,
  });
  const window = new THREE.Mesh(windowGeometry, windowMaterial);
  window.name = marker + "_" + id;
  window.position.set(position.x, position.y, position.z);
  // window.rotation.set(rotation.x, rotation.y, rotation.z);
  rotation && window.rotateY(rotation.y);
  group.add(window);
  return group;
};

ModelLoader.prototype.setCamera = function () {
  // this.camera.up.set(0, 1, 0)
  // let relativeCameraOffset = new THREE.Vector3(0, 0, 1);
  // let cameraOffset = relativeCameraOffset.applyMatrix4(this.target.matrixWorld);
};
ModelLoader.prototype.rotateCamera = function (offest) {
  this.offestY += offest * 1000;
};
ModelLoader.prototype.initGlbModel = function ({
  src,
  position,
  marker,
  scale,
  rotate,
  animation,
  clone,
  clonePosition,
  cloneScale,
}) {
  const _this = this;
  let dracoLoader = new THREE.DRACOLoader();
  let gltfLoader = new THREE.GLTFLoader(this.loadingManager);
  dracoLoader.setDecoderPath("./draco/");
  dracoLoader.setDecoderConfig({ type: "js" }); //使用兼容性强的draco_decoder.js解码器
  dracoLoader.preload();
  gltfLoader.setDRACOLoader(dracoLoader);
  let group = new THREE.Group();
  let callbackOnLoad = function (object) {
    object.scene.castShadow = true;
    scale && object.scene.scale.set(scale, scale, scale);
    rotate && object.scene.rotation.set(rotate.x, rotate.y, rotate.z);
    position && object.scene.position.set(position.x, position.y, position.z);
    object.scene.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.name = marker + "_" + child.name;
        child.frustumCulled = true;
        child.receiveShadow = true;
        child.frustumCulled = true;
        child.material = new THREE.MeshStandardMaterial({
          map: child.material.map, //获取原来材质的颜色贴图属性值
          color: child.material.color, //读取原来材质的颜色
          metalness: 0.7,
          roughness: 0.65,
          transparent: true,
          side: THREE.DoubleSide,
        });
      }
    });
    if (animation) {
      // 创建动画
      _this.logoMixer = object.scene.mixer = new THREE.AnimationMixer(
        object.scene
      );
      // _this.logoAnimation = object.animations;
      // console.log(object.animations)
      _this.logoActions = _this.logoMixer.clipAction(object.animations[0]);
      // _this.logoActions.play()
      // console.log(_this.logoActions)
    }
    // console.log(object.scene)
    if (clone) {
      let cloneModel = object.scene.clone();
      cloneScale && cloneModel.scale.set(cloneScale, cloneScale, cloneScale);
      cloneModel.position.set(
        clonePosition.x,
        clonePosition.y,
        clonePosition.z
      );
      _this.scene.add(cloneModel);
    }
    group.add(object.scene);
    _this.scene.add(group);
    // _this.logoActions && _this.logoActions.play()
  };
  gltfLoader.load(src, callbackOnLoad, null, null, null, false);
};
// 加载fbx人物模型
ModelLoader.prototype.loadFbxModel = function (src) {
  //加载模型
  const _this = this;
  // 创建网格
  let geometry = new THREE.BoxBufferGeometry(0.5, 1, 0.5);
  geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0.5, 0));
  this.target = new THREE.Mesh(
    geometry,
    new THREE.MeshNormalMaterial({
      transparent: true,
      opacity: 0,
    })
  );
  this.target.castShadow = true;
  this.scene.add(this.target);
  let dracoLoader = new THREE.DRACOLoader();
  let loader = new THREE.GLTFLoader(this.loadingManager);
  dracoLoader.setDecoderPath("./draco/");
  dracoLoader.setDecoderConfig({ type: "js" }); //使用兼容性强的draco_decoder.js解码器
  dracoLoader.preload();
  loader.setDRACOLoader(dracoLoader);
  loader.load(src, function (object) {
    let mesh = object.scene;
    // console.log(object);
    //设置模型的每个部位都可以投影
    mesh.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = new THREE.MeshStandardMaterial({
          // map: child.material.map, //获取原来材质的颜色贴图属性值
          color: 0xffffff, //读取原来材质的颜色
          metalness: 0.4,
          roughness: 0.35,
          transparent: false,
        });
      }
    });
    let skeleton = new THREE.SkeletonHelper(mesh);
    skeleton.visible = false;
    _this.scene.add(skeleton);

    //创建动画
    _this.mixer = mesh.mixer = new THREE.AnimationMixer(mesh);
    _this.animations = object.animations;
    for (let i = 0; i < object.animations.length; i++) {
      _this.actions[i] = _this.mixer.clipAction(object.animations[i]);
    }
    mesh.scale.set(80, 80, 80);
    mesh.position.y += 15;
    mesh.rotation.y = Math.PI;
    //设置光线焦点模型
    _this.directionalLight.target = mesh;
    _this.target.add(mesh);
    _this.target.position.set(-111, 15, -5176);
    _this.target.rotation.y = 0.1;
    // 添加光源
    _this.manPointLight = new THREE.DirectionalLight(
      new THREE.Color(0xffffff),
      0.5
    );
    _this.manPointLight.position.set(-111, 20, -5176);
    _this.scene.add(_this.manPointLight);
    _this.activateAllActions();
    _this.initRayCaster();
    _this.createLine();
  });
};
ModelLoader.prototype.loadOtherModel = function () {
  const _this = this
  // 生成logo背景
  let logoBoard = _this.setArtBoard({
    width: 1200,
    height: 430,
    length: 50,
    position: {
      x: 4200,
      y: 250,
      z: 160,
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0,
    },
    transparent: false,
    opacity: 0.95,
  });
  _this.scene.add(logoBoard);
  let logoBoard2 = _this.setArtBoard({
    width: 1000,
    height: 430,
    length: 50,
    position: {
      x: 4200,
      y: 250,
      z: 160,
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0,
    },
    transparent: false,
    opacity: 0.95,
  });
  logoBoard2.position.x -= 8460;
  logoBoard2.position.z -= 100;
  // logoBoard2.position.y += 200
  _this.scene.add(logoBoard2);
  // 加载模型
  _this.initGlbModel({
    src: "./model/logo-text.glb",
    position: { x: -28, y: 430, z: -4585 },
    scale: 40,
    rotate: { x: 0, y: 110, z: 0 },
  });
  setTimeout(() => {
    LOGO_BOARDS_2.forEach((item) => {
      // console.log(item)
      let sprite = _this.setArtBoard({
        width: item.width || 100,
        height: item.height || 100,
        length: 1,
        id: item.sort || item.id,
        other: item.id,
        position: {
          x: item.position.x,
          y: item.position.y,
          z: item.position.z,
        },
        image: item.image,
        rotation: {
          x: 0,
          y: -Math.PI / 2,
          z: 0,
        },
        marker: "LOGO2",
      });
      // console.log(sprite)
      sprite.position.x -= 7260;
      sprite.position.y -= 100;
      sprite.position.z -= 880;
      _this.scene.add(sprite);
    });
  }, 2000)
  setTimeout(() => {
    LGGO_BORAD.forEach((item) => {
      // console.log(item)
      let sprite = _this.setArtBoard({
        width: item.width || 100,
        height: item.height || 100,
        length: 1,
        id: item.sort || item.id,
        other: item.id,
        position: {
          x: item.position.x,
          y: item.position.y,
          z: item.position.z,
        },
        image: item.image,
        rotation: {
          x: 0,
          y: -Math.PI / 2,
          z: 0,
        },
        marker: "LOGO",
      });
      // console.log(sprite)
      sprite.position.x += 1260;
      sprite.position.y -= 100;
      _this.scene.add(sprite);
    });
    _this.initGlbModel({
      src: "./model/qy.glb",
      position: { x: -1070, y: 50, z: -4380 },
      scale: 100,
      rotate: { x: 0, y: 110, z: 0 },
    });
  }, 4 * 1000)
},
ModelLoader.prototype.initTag = function (name, point) {
  let labelContent = this.nameTag(name);
  point = Object.assign(point, { y: point.y + 1 });
  labelContent.position.copy(point);
  this.labelContents.push(labelContent);
  this.scene.add(labelContent);
};
// 名称数据信息框
ModelLoader.prototype.nameTag = function (name) {
  let div = document.createElement("div");
  div.classList.add("tag");
  div.innerHTML = name;
  div.style.pointerEvents = "none";
  let label = new THREE.CSS2DObject(div);
  label.name = name;
  return label;
};
/**
 * 清除名称弹框
 */
ModelLoader.prototype.clearTag = function () {
  if (this.labelContents.length > 0) {
    this.labelContents.forEach((item) => {
      this.scene.remove(item);
    });
    this.labelContent = [];
  }
};
/**
 * 加载园区模型
 * @param {*} src 模型地址
 */
ModelLoader.prototype.initContent = function (src, logos) {
  this.initModel("CG", src, "MODEL", logos);
};
ModelLoader.prototype.createLine = function () {
  const _this = this;
  //创建样条曲线，作为运动轨迹
  this.curve = new THREE.CatmullRomCurve3(
    [
      // 23
      new THREE.Vector3(-90, 100, -4850),
      new THREE.Vector3(-3563, 100, -3625),
      new THREE.Vector3(-5175, 100, -85),
      new THREE.Vector3(-2810, 100, 4365),
      new THREE.Vector3(56, 100, 5241),
      new THREE.Vector3(3580, 100, 3802),
      new THREE.Vector3(4951, 100, 337),
      new THREE.Vector3(3318, 100, -3681),
    ],
    true
  );
  _this.loadControl();
  const points = this.curve.getPoints(5000);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  // 材质对象
  const material = new THREE.LineBasicMaterial({
    color: "red",
    transparent: true,
    opacity: 0,
  });
  // 线条模型对象
  const line = new THREE.Line(geometry, material);
  line.visible = false
  this.scene.add(line); // 线条对象添加到场景中
  setTimeout(() => {
    _this.loadExhibition()
  }, 1000)
};
// 加载展华
ModelLoader.prototype.loadExhibition = function () {
  const _this = this
  // jin获奖作品最后的位置
  let awardLastInedx = 0;
  // 银
  let awardYLastInedx = 0;
  // 铜
  let awardTLastInedx = 0;
  // 评委
  let awardPWLastInedx = 0;
  // 1-CIB8主题奖-文物遇见插画最后的位置
  let CIB81LastIndex = 0;
  // 2-CIB8主题奖-万博城市插画最后的位置
  let CIB82LastIndex = 0;
  // 3-CIB8主题奖-数字艺术元宇宙
  let CIB83LastIndex = 0;
  // 5-CIB8主题奖-华为最佳创意奖
  let CIB84LastIndex = 0;
  // 6-CIB8主题奖-微博美学人气奖
  let CIB85LastIndex = 0;
  // 7-CIB8主题奖-网易LOFTER人气奖
  let CIB86LastIndex = 0;
  // 8-CIB8主题奖-名创优品
  let CIB87LastIndex = 0
  // 9-CIB8主题奖-命题绘本奖
  let CIB88LastIndex = 0
  // 10-CIB8主题奖-原创绘本奖
  let CIB89LastIndex = 0
  // 内圈
  this.arc1 = this.drawArcCurce(150);
  this.arc2 = this.drawArcCurce(250);
  this.arc3 = this.drawArcCurce(350);
  // 外圈
  this.arcOut1 = this.drawArcOutCurce(150);
  this.arcOut2 = this.drawArcOutCurce(250);
  this.arcOut3 = this.drawArcOutCurce(350);
  // const point = this.arc1.getLength()
  // console.log(_this.arc1, point)
  const imgWidthScale = [
    {
      inter: [0, 150],
      scale: 0.005,
    },
    {
      inter: [150, 300],
      scale: 0.01,
    },
    {
      inter: [300, 450],
      scale: 0.01,
    },
    {
      inter: [450, 600],
      scale: 0.02,
    },
    {
      inter: [600, 750],
      scale: 0.03,
    },
    {
      inter: [750, 800],
      scale: 0.02,
    },
  ];
  // .concat(SLIVER_BORAD_IN)
  //   .concat(COPPER_BORAD_IN)
  //   .concat(JUDGE_BORAD_IN)
  // 内圈
  let result = GLOD_BORAD_IN;
  let count = 1 / result.length / 10;
  // console.log(result, count)
  result.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        // let process = (((index) / 1) * 0.03) + 0
        let process = 0.05 + count * (index);
        // let nextProcess = process + 0.01
        let position = _this.arc1.getPointAt(process);
        // let yx = new THREE.Vector3(0, 0, 0)
        // let nextPosition = _this.arc1.getPointAt(nextProcess);
        // // console.log(iW['scale'], item.width)
        // let rotation = position.clone().cross(nextPosition) // Vector3(5, 5, -1)
        // console.log(position.cross(nextPosition))
        // console.log(0.3 + (index * 0.1))
        let ry = 0.4 + 0.05 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        if (index === result.length - 1) {
          awardLastInedx = process;
        }
        break;
      }
    }
  });
  // 外圈
  let resultOut = GLOD_BORAD_OUT
  let countOut = 1 / resultOut.length / 20;
  // console.log(countOut)
  resultOut.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        // let process = (((index) / 1) * 0.03) + 0
        // let process = 0.04 + countOut * (index - 1);
        // let position = _this.arcOut1.getPointAt(process);
        let process = 0.05 + countOut * (index);
        // let nextProcess = process + 0.01
        let position = _this.arcOut1.getPointAt(process);
        // let nextPosition = _this.arcOut1.getPointAt(nextProcess);
        // console.log(iW['scale'], item.width)
        let ry = 0.4 + 0.05 * index
        // console.log({ nextPosition, position }, (Math.PI / 2) - nextPosition.angleTo(position))
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        break;
      }
    }
  });
  let result2 = SLIVER_BORAD_IN;
  let count2 = 1 / result.length / 13;
  result2.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = awardLastInedx + 0.02 + count2 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arc1.getPointAt(process);
        let ry = 0.9 + 0.13 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        if (index === result2.length - 1) {
          awardYLastInedx = process;
        }
        break;
      }
    }
  });
  let resultOut2 = SLIVER_BORAD_OUT
  let countOut2 = 1 / resultOut.length / 22;
  // console.log(countOut)
  resultOut2.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = 0.1 + countOut2 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arcOut1.getPointAt(process);
        let ry = 0.7 + 0.13 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        break;
      }
    }
  });
  let result3 = COPPER_BORAD_IN;
  let count3 = 1 / result.length / 13;
  result3.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = awardYLastInedx + 0.01 + count3 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arc1.getPointAt(process);
        let ry = 1.9 + 0.13 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        if (index === result3.length - 1) {
          awardTLastInedx = process;
        }
        break;
      }
    }
  });
  let resultOut3 = COPPER_BORAD_IN
  let countOut3 = 1 / resultOut.length / 22;
  // console.log(countOut)
  resultOut3.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = 0.15 + countOut3 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arcOut1.getPointAt(process);
        let ry = 1.2 + 0.13 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        break;
      }
    }
  });
  let result4 = JUDGE_BORAD_IN;
  let count4= 1 / result.length / 13;
  result4.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = awardTLastInedx + count4 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arc1.getPointAt(process);
        let ry = 0.4 + 0.05 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        if (index === result4.length - 1) {
          awardPWLastInedx = process;
        }
        break;
      }
    }
  });
  let resultOut4 = JUDGE_BORAD_OUT
  let countOut4 = 1 / resultOut.length / 22;
  // console.log(countOut)
  resultOut4.forEach((item, index) => {
    for (let i = 0; i < imgWidthScale.length; i++) {
      let iW = imgWidthScale[i];
      if (item.width > iW.inter[0] && item.width <= iW.inter[1]) {
        let process = 0.15 + countOut4 * (index) + iW.scale;
        // let nextProcess = process + 0.01
        let position = _this.arcOut1.getPointAt(process);
        let ry = 0.4 + 0.05 * index
        let sprite = _this.setArtBoard({
          id: item.id,
          width: item.width,
          height: item.height,
          length: item.length,
          position: position,
          // position: item.position,
          image: item.image,
          rotation: {
            x: 0,
            y: ry,
            z: 0
          },
          marker: "PRO",
        });
        _this.scene.add(sprite);
        break;
      }
    }
  });
  setTimeout(() => {

  // 1-CIB8主题奖-文物遇见插画 3 * 7
  // let resultMain = BOARD_IN_1
  const threePartIndex = Math.ceil(BOARD_IN_1.length / 3);
  let resultMain = [
    BOARD_IN_1.splice(-threePartIndex),
    BOARD_IN_1.splice(-threePartIndex),
    BOARD_IN_1,
  ];
  // console.log(resultMain)
  resultMain.forEach((item, index) => {
    let countMain = 1 / item.length / 30;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = awardPWLastInedx + 0.015 + countMain * (cIndex - 1);
            let position = _this[`arc${index + 1}`].getPointAt(process);
            let ry = 0.6 + 0.13 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              image: cItem.image,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              marker: "PRO",
            });
            if (
              index === resultMain.length - 1 &&
              cIndex === cItem.length - 1
            ) {
              CIB81LastIndex = process;
            }
            // console.log(sprite)
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 2-CIB8主题奖-万博城市-BOARD_IN_2 3 * 20
  let BOARD_IN_2_ARR = [];
  for (let i = BOARD_IN_2.start; i < BOARD_IN_2.end; i++) {
    BOARD_IN_2_ARR.push({
      id: i,
      length: BOARD_IN_2.length,
      image: BOARD_IN_2.file + i + "." + BOARD_IN_2.unit,
    });
  }
  const threePart2Index = Math.ceil(BOARD_IN_2_ARR.length / 3);
  let resultMain2 = [
    BOARD_IN_2_ARR.splice(-threePart2Index),
    BOARD_IN_2_ARR.splice(-threePart2Index),
    BOARD_IN_2_ARR,
  ];
  // console.log(resultMain2)
  resultMain2.forEach((item, index) => {
    let countMain = 1 / item.length / 8;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB81LastIndex + 0.03 + countMain * (cIndex - 1);
            let position = _this[`arc${index + 1}`].getPointAt(process);
            let ry = 0.8 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain2.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB82LastIndex = process;
              // console.log({CIB82LastIndex})
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 3-CIB8主题奖-数字艺术元宇宙
  let BOARD_IN_3_ARR = [];
  for (let i = BOARD_IN_3.start; i < BOARD_IN_3.end; i++) {
    BOARD_IN_3_ARR.push({
      id: i,
      length: BOARD_IN_3.length,
      image: BOARD_IN_3.file + i + "." + BOARD_IN_3.unit,
    });
  }
  const threePart3Index = Math.ceil(BOARD_IN_3_ARR.length / 3);
  let resultMain3 = [
    BOARD_IN_3_ARR.splice(-threePart3Index),
    BOARD_IN_3_ARR.splice(-threePart3Index),
    BOARD_IN_3_ARR,
  ];
  // console.log(resultMain2)
  resultMain3.forEach((item, index) => {
    let countMain = 1 / item.length / 8;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB82LastIndex + 0.02 + countMain * (cIndex - 1);
            // console.log(3, process)
            let position = _this[`arc${index + 1}`].getPointAt(process);
            let ry = 1.5 + 0.2 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain3.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB83LastIndex = process;
              // console.log({CIB82LastIndex})
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 4-视觉中国最具商业价值插画师
  let BOARD_IN_4_ARR = [];
  for (let i = BOARD_IN_4.start; i < BOARD_IN_4.end; i++) {
    BOARD_IN_4_ARR.push({
      id: i,
      length: BOARD_IN_4.length,
      image: BOARD_IN_4.file + i + "." + BOARD_IN_4.unit,
    });
  }
  const threePart4Index = Math.ceil(BOARD_IN_4_ARR.length / 3);
  let resultMain4 = [
    BOARD_IN_4_ARR.splice(-threePart4Index),
    BOARD_IN_4_ARR.splice(-threePart4Index),
    BOARD_IN_4_ARR,
  ];
  // console.log(resultMain2)
  resultMain4.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = 0.3 + 0.02 + countMain * (cIndex - 1);
            // console.log(3, process)
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let ry = 2 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain4.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB84LastIndex = process;
              // console.log({CIB82LastIndex})
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 5-CIB8主题奖-华为最佳创意奖
  let BOARD_IN_5_ARR = [];
  for (let i = BOARD_IN_5.start; i < BOARD_IN_5.end; i++) {
    BOARD_IN_5_ARR.push({
      id: i,
      length: BOARD_IN_5.length,
      image: BOARD_IN_5.file + i + "." + BOARD_IN_5.unit,
    });
  }
  const threePart5Index = Math.ceil(BOARD_IN_5_ARR.length / 3);
  let resultMain5 = [
    BOARD_IN_5_ARR.splice(-threePart5Index),
    BOARD_IN_5_ARR.splice(-threePart5Index),
    BOARD_IN_5_ARR,
  ];
  // console.log(resultMain2)
  resultMain5.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB84LastIndex + 0.02 + countMain * (cIndex - 1);
            // console.log(3, process)
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let ry = 2.3 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain5.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB85LastIndex = process;
              // console.log({CIB85LastIndex})
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 6-CIB8主题奖-微博美学人气奖
  let BOARD_IN_6_ARR = [];
  for (let i = BOARD_IN_6.start; i < BOARD_IN_6.end; i++) {
    BOARD_IN_6_ARR.push({
      id: i,
      length: BOARD_IN_6.length,
      image: BOARD_IN_6.file + i + "." + BOARD_IN_6.unit,
    });
  }
  const threePart6Index = Math.ceil(BOARD_IN_6_ARR.length / 3);
  let resultMain6 = [
    BOARD_IN_6_ARR.splice(-threePart6Index),
    BOARD_IN_6_ARR.splice(-threePart6Index),
    BOARD_IN_6_ARR,
  ];
  // console.log(resultMain2)
  resultMain6.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB85LastIndex + 0.02 + countMain * (cIndex - 1);
            // console.log(3, process)
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let ry = 2.6 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain6.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB86LastIndex = process;
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 7-CIB8主题奖-网易LOFTER人气奖
  let BOARD_IN_7_ARR = [];
  for (let i = BOARD_IN_7.start; i < BOARD_IN_7.end; i++) {
    BOARD_IN_7_ARR.push({
      id: i,
      length: BOARD_IN_7.length,
      image: BOARD_IN_7.file + i + "." + BOARD_IN_7.unit,
    });
  }
  const threePart7Index = Math.ceil(BOARD_IN_7_ARR.length / 3);
  let resultMain7 = [
    BOARD_IN_7_ARR.splice(-threePart7Index),
    BOARD_IN_7_ARR.splice(-threePart7Index),
    BOARD_IN_7_ARR,
  ];
  // console.log(resultMain2)
  resultMain7.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB86LastIndex + 0.02 + countMain * (cIndex - 1);
            // console.log(3, process)
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let ry = 3 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain7.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB87LastIndex = process
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 8-CIB8主题奖-名创优品
  let BOARD_IN_8_ARR = [];
  for (let i = BOARD_IN_8.start; i < BOARD_IN_8.end; i++) {
    BOARD_IN_8_ARR.push({
      id: i,
      length: BOARD_IN_8.length,
      image: BOARD_IN_8.file + i + "." + BOARD_IN_8.unit,
    });
  }
  const threePart8Index = Math.ceil(BOARD_IN_8_ARR.length / 3);
  let resultMain8 = [
    BOARD_IN_8_ARR.splice(-threePart8Index),
    BOARD_IN_8_ARR.splice(-threePart8Index),
    BOARD_IN_8_ARR,
  ];
  // console.log(resultMain2)
  resultMain8.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB87LastIndex + 0.02 + countMain * (cIndex - 1);
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let ry = 0.0 + 0.17 * index
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              rotation: {
                x: 0,
                y: ry,
                z: 0
              },
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === resultMain8.length - 1 &&
              cIndex === item.length - 1
            ) {
              CIB88LastIndex = process
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // 9-CIB8主题奖-命题绘本奖
  let BOARD_IN_9_ARR = [];
  for (let i = BOARD_IN_9.start; i < BOARD_IN_9.end; i++) {
    BOARD_IN_9_ARR.push({
      id: i,
      length: BOARD_IN_9.length,
      image: BOARD_IN_9.file + i + "." + BOARD_IN_9.unit,
    });
  }
  const threePart9Index = Math.ceil(BOARD_IN_9_ARR.length / 3);
  let resultMain9 = [
    BOARD_IN_9_ARR.splice(-threePart9Index),
    BOARD_IN_9_ARR.splice(-threePart9Index),
    BOARD_IN_9_ARR,
  ];
  // console.log(resultMain9)
  // let process = 0
  resultMain9.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    // console.log(item)
    item.forEach((cItem, cIndex) => {
      // console.log(index, cIndex)
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB88LastIndex + 0.02 + countMain * (cIndex - 1);
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === 2 &&
              cIndex === item.length - 1
            ) {
              CIB89LastIndex = process
              console.log('CIB89LastIndex', CIB89LastIndex)
            }
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  // console.log('CIB89LastIndex', CIB89LastIndex)
  // 10-CIB8主题奖-原创绘本奖
  let BOARD_IN_10_ARR = [];
  for (let i = BOARD_IN_10.start; i < BOARD_IN_10.end; i++) {
    BOARD_IN_10_ARR.push({
      id: i,
      length: BOARD_IN_10.length,
      image: BOARD_IN_10.file + i + "." + BOARD_IN_10.unit,
    });
  }
  const threePart10Index = Math.ceil(BOARD_IN_10_ARR.length / 3);
  let resultMain10 = [
    BOARD_IN_10_ARR.splice(-threePart10Index),
    BOARD_IN_10_ARR.splice(-threePart10Index),
    BOARD_IN_10_ARR,
  ];
  resultMain10.forEach((item, index) => {
    let countMain = 1 / item.length / 35;
    item.forEach((cItem, cIndex) => {
      getNaturalWidth(cItem.image).then((res) => {
        const { width } = res;
        for (let i = 0; i < imgWidthScale.length; i++) {
          let iW = imgWidthScale[i];
          if (width > iW.inter[0] && width <= iW.inter[1]) {
            let process = CIB89LastIndex + 0.02 + countMain * (cIndex - 1);
            let position = _this[`arcOut${index + 1}`].getPointAt(process);
            let sprite = _this.setArtBoard({
              id: cItem.id,
              width: 100,
              height: 100,
              length: cItem.length,
              position: position,
              image: cItem.image,
              marker: "PRO",
            });
            if (
              index === 2 &&
              cIndex === item.length - 1
            )
            _this.scene.add(sprite);
          }
        }
      });
    });
  });
  }, 8 * 1000)
  setTimeout(() => {
    _this.scene.background = _this.getTexturesFromAtlasFile();
  }, 10 * 1000)
};
// 绘制圆弧
ModelLoader.prototype.drawArcCurce = function (height) {
  //创建样条曲线，作为运动轨迹
  let curve = new THREE.CatmullRomCurve3(
    [
      // 23
      new THREE.Vector3(26, height, -4445),
      new THREE.Vector3(-3138, height, -3374),
      new THREE.Vector3(-4793, height, 136),
      new THREE.Vector3(-3054, height, 3524),
      new THREE.Vector3(27, height, 4804),
      new THREE.Vector3(3062, height, 3661),
      new THREE.Vector3(4683, height, 429),
      new THREE.Vector3(2621, height, -3660),
    ],
    true,
    "centripetal",
    2
  );
  const points = curve.getPoints(50);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  // 材质对象
  const material = new THREE.LineBasicMaterial({
    color: "red",
    transparent: true,
    opacity: 0.1,
  });
  // 线条模型对象
  const line = new THREE.Line(geometry, material);
  console.log(line)
  line.visible = false
  this.scene.add(line); // 线条对象添加到场景中
  return curve;
};
// 绘制圆弧
ModelLoader.prototype.drawArcOutCurce = function (height) {
  //创建样条曲线，作为运动轨迹
  let curve = new THREE.CatmullRomCurve3(
    [
      // 23
      new THREE.Vector3(-56, height, -5473),
      new THREE.Vector3(-3729, height, -3994),
      new THREE.Vector3(-5782, height, 40),
      new THREE.Vector3(-3340, height, 4447),
      new THREE.Vector3(166, height, 5538),
      new THREE.Vector3(3566, height, 4359),
      new THREE.Vector3(5469, height, 285),
      new THREE.Vector3(3576, height, -4131),
    ],
    true,
    "centripetal",
    2
  );
  const points = curve.getPoints(50);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  // 材质对象
  const material = new THREE.LineBasicMaterial({
    color: "red",
    transparent: true,
    opacity: 0,
  });
  // 线条模型对象
  const line = new THREE.Line(geometry, material);
  line.visible = false
  this.scene.add(line); // 线条对象添加到场景中
  return curve;
};
ModelLoader.prototype.loadControl = function () {
  const _this = this;
  const ID = "circle";
  class JoyStick {
    constructor(options) {
      const circle = document.createElement("div");
      circle.id = "circle";
      circle.style.cssText =
        "position:absolute; bottom:35px; width:85px; height:85px; background:rgba(126, 126, 126, 0.3); border: rgba(0, 0, 0, .3) solid medium; border-radius:50%; " +
        options.position +
        "";
      const thumb = document.createElement("div");
      thumb.style.cssText =
        "position: absolute; left: 20px; top: 20px; width: 40px; height: 40px; border-radius: 50%; background: rgba(255, 255, 255, .9);";
      circle.appendChild(thumb);
      document.body.appendChild(circle);
      this.domElement = thumb;
      this.maxRadius = options.maxRadius || 40;
      this.maxRadiusSquared = this.maxRadius * this.maxRadius;
      this.onMove = options.onMove;
      this.game = options.game;
      this.origin = {
        left: this.domElement.offsetLeft,
        top: this.domElement.offsetTop,
      };
      this.rotationDamping = options.rotationDamping || 0.06;
      this.moveDamping = options.moveDamping || 0.01;
      if (this.domElement != undefined) {
        const joystick = this;
        if ("ontouchstart" in window) {
          this.domElement.addEventListener("touchstart", function (evt) {
            evt.preventDefault();
            joystick.tap(evt);
          });
        } else {
          this.domElement.addEventListener("mousedown", function (evt) {
            evt.preventDefault();
            joystick.tap(evt);
          });
        }
      }
    }

    getMousePosition(evt) {
      let clientX = evt.targetTouches
        ? evt.targetTouches[0].pageX
        : evt.clientX;
      let clientY = evt.targetTouches
        ? evt.targetTouches[0].pageY
        : evt.clientY;
      return {
        x: clientX,
        y: clientY,
      };
    }

    tap(evt) {
      evt = evt || window.event;
      // get the mouse cursor position at startup:
      this.offset = this.getMousePosition(evt);
      const joystick = this;
      if ("ontouchstart" in window) {
        this.domElement.ontouchmove = function (evt) {
          joystick.move(evt);
        };
        this.domElement.ontouchend = function (evt) {
          joystick.up(evt);
        };
      } else {
        this.domElement.onmousemove = function (evt) {
          joystick.move(evt);
        };
        this.domElement.onmouseup = function (evt) {
          joystick.up(evt);
        };
      }
    }

    move(evt) {
      evt = evt || window.event;
      const mouse = this.getMousePosition(evt);
      // calculate the new cursor position:
      let left = mouse.x - this.offset.x;
      let top = mouse.y - this.offset.y;
      //this.offset = mouse;

      const sqMag = left * left + top * top;
      if (sqMag > this.maxRadiusSquared) {
        //Only use sqrt if essential
        const magnitude = Math.sqrt(sqMag);
        left /= magnitude;
        top /= magnitude;
        left *= this.maxRadius;
        top *= this.maxRadius;
      }
      // set the element's new position:
      this.domElement.style.top = `${top + this.domElement.clientHeight / 2}px`;
      this.domElement.style.left = `${
        left + this.domElement.clientWidth / 2
      }px`;

      //@TODO use nipple,js
      const forward =
        -(top - this.origin.top + this.domElement.clientHeight / 2) /
        this.maxRadius;
      const turn =
        (left - this.origin.left + this.domElement.clientWidth / 2) /
        this.maxRadius;

      if (this.onMove != undefined) this.onMove.call(this.game, forward, turn);
    }

    up() {
      if ("ontouchstart" in window) {
        this.domElement.ontouchmove = null;
        this.domElement.touchend = null;
      } else {
        this.domElement.onmousemove = null;
        this.domElement.onmouseup = null;
      }
      this.domElement.style.top = `${this.origin.top}px`;
      this.domElement.style.left = `${this.origin.left}px`;

      this.onMove.call(this.game, 0, 0);
    }
  }
  if (!document.getElementById(ID)) {
    // new JoyStick({
    //   onMove: (forward, turn) => {
    //     _this.setup.forward = forward;
    //     _this.setup.turn = -turn;
    //   },
    //   position: "left: 5%",
    // });
  }
  // 生成第三人称视角
  this.followCamera = new THREE.Object3D();
  this.followCamera.position.copy({
    x: this.camera.position.x,
    y: 170,
    z: this.camera.position.z,
  });
  this.scene.add(this.followCamera);
  if (this.curve) {
    // 初始视角
    const point = this.curve.getPointAt(this.progress); //获取样条曲线指定点坐标，作为相机的位置
    const pointBox = this.curve.getPointAt(this.progress + 0.0002 * 20); //获取样条曲线指定点坐标
    const targetPoint = this.curve.getPointAt(this.progress + 0.0002 * 65); //获取样条曲线指定点坐标
    this.camera.position.set(point.x, point.y + 80, point.z);
    this.camera.lookAt(pointBox.x, pointBox.y + 80, pointBox.z);
    this.target.position.set(targetPoint.x, targetPoint.y - 80, targetPoint.z);
    // this.controls.position0.set(targetPoint.x, targetPoint.y + 80, targetPoint.z) //非必要，场景有控件时才加上
    // this.controls.target.set(targetPoint.x, targetPoint.y + 80, targetPoint.z) //非必要，场景有控件时才加上
    this.followCamera.parent = this.target;
  }
};
ModelLoader.prototype.initRayCaster = function () {
  // this.raycasterUp.ray.direction = new THREE.Vector3(0, 1, 0)
  // this.raycasterDown.ray.direction = new THREE.Vector3(0, -1, 0)
  this.raycasterFront.ray.direction = new THREE.Vector3(1, 0, 0);
  // this.raycasterBack.ray.direction = new THREE.Vector3(-1, 0, 0)
  // this.raycasterLeft.ray.direction = new THREE.Vector3(0, 0, -1)
  // this.raycasterRight.ray.direction = new THREE.Vector3(0, 0, 1)
  // console.log(this.target);
  // this.raycasterUp.far = 1.2
  // this.raycasterDown.far = this.player.body.height
  // this.raycasterFront.far = this.player.body.width
  // this.raycasterBack.far = this.player.body.width
  // this.raycasterLeft.far = this.player.body.width
  // this.raycasterRight.far = this.player.body.width
};
ModelLoader.prototype.updateDrive = function () {
  if (!this.target) return;
  const maxSteerVal = 0.01;
  const maxForce = this.touchRun ? 12 : 24;
  const force = maxForce * this.setup.forward;
  const steer = maxSteerVal * this.setup.turn;
  if (this.setup.forward != 0) {
    // this.raycasterFront.ray.origin = this.target.position
    // let c1 = this.raycasterFront.intersectObject(this.meshList)
    // 计算出镜头的朝向
    // let vector = new THREE.Vector3(0, 0, -1).applyQuaternion(
    //   this.camera.quaternion
    // );
    // let direction = Math.atan2(vector.x, vector.z);
    // this.raycasterFront.setFromCamera({x: this.target.position.x, y: this.target.position.y}, this.camera);
    // // console.log({x: this.target.position.x, y: this.target.position.y})
    // // console.log(this.meshList[17])
    // const intersection = this.raycasterFront.intersectObject(this.meshList[16]);
    // console.log(intersection.length);
    this.target.translateZ(force);
    if (this.actions[3]) {
      if (this.touchRun) {
        !this.actions[1].isRunning() && this.actions[1].play();
        this.actions[3].isRunning() && this.actions[3].stop();
      } else {
        // console.log(this.actions[3].isRunning())
        !this.actions[3].isRunning() && this.actions[3].play();
        this.actions[1].isRunning() && this.actions[1].stop();
      }
      this.actions[0].stop();
    }
  } else {
    this.actions[0] && !this.actions[0].isRunning() && this.actions[0].play();
    this.actions[3] && this.actions[3].isRunning() && this.actions[3].stop();
    this.actions[1] && this.actions[1].isRunning() && this.actions[1].stop();
  }
  this.target.rotateY(steer);
  // this.target.rotateOnAxis(new THREE.Vector3(0, 1, 0), steer);
};
ModelLoader.prototype.setWeight = function (action, weight) {
  action.enabled = true;
  action.setEffectiveTimeScale(1);
  action.setEffectiveWeight(weight);
};
ModelLoader.prototype.activateAllActions = function () {
  this.actions.forEach(function (action, index) {
    if ([0, 3, 1].includes(index)) {
      action.play();
    }
  });
};
// 循环碰撞检测
ModelLoader.prototype.ray = function () {
  if (!this.ray) return;
  // 将方向向量初始化
  // console.log(this.scene.children)
  // 检测射线与多个物体的相交情况
  // let collisionResults = this.raycaster.intersectObjects([this.room], true);
  // 如果返回结果不为空，且交点与射线起点的距离小于物体中心至顶点的距离，则发生了碰撞
  // console.log(collisionResults)
  // if (collisionResults.length > 0 && collisionResults[0].distance < 0) {
  //  console.log('碰撞！');
  // }
};
// 沿着轨迹行走
ModelLoader.prototype.toWalk = function (type) {
  this.walkTop(type);
};
ModelLoader.prototype.walkTop = function (type) {
  this.walkTowers = type;
  // console.log()
};
ModelLoader.prototype.toWalkStop = function () {
  this.walkTowers = null;
};
ModelLoader.prototype.updateGlobalCamera = function () {
  if (this.followCamera && this.target && this.curve && this.controls) {
    const point = this.curve.getPointAt(this.progress); //获取样条曲线指定点坐标，作为相机的位置
    // this.controls.position0.set(targetPoint.x, targetPoint.y + 80, targetPoint.z) //非必要，场景有控件时才加上
    // this.controls.target.set(targetPoint.x, targetPoint.y + 80, targetPoint.z) //非必要，场景有控件时才加上
    if (this.walkTowers === "top") {
      if (this.actions[3]) {
        if (this.touchRun) {
          !this.actions[1].isRunning() && this.actions[1].play();
          this.actions[3].isRunning() && this.actions[3].stop();
        } else {
          !this.actions[3].isRunning() && this.actions[3].play();
          this.actions[1].isRunning() && this.actions[1].stop();
        }
        this.actions[0].stop();
      }
      if (this.progress >= 0.98) {
        this.progress = 0;
      } else {
        this.progress += 0.0002;
      }
      const pointBox = this.curve.getPointAt(this.progress + 0.0002 * 20); //获取样条曲线指定点坐标
      const targetPoint = this.curve.getPointAt(this.progress + 0.0002 * 65); //获取样条曲线指定点坐标
      let targetPos = pointBox; //目标位置点
      let offsetAngle = 0; //目标移动时的朝向偏移
      this.camera.position.set(point.x, point.y + 80, point.z);
      this.camera.lookAt(pointBox.x, pointBox.y + 80, pointBox.z);
      this.target.position.set(
        targetPoint.x,
        targetPoint.y - 80,
        targetPoint.z
      );
      this.controls.position0.set(
        targetPoint.x,
        targetPoint.y + 80,
        targetPoint.z
      ); //非必要，场景有控件时才加上
      this.controls.target.set(
        targetPoint.x,
        targetPoint.y + 80,
        targetPoint.z
      ); //非必要，场景有控件时才加上
      // //以下代码在多段路径时可重复执行
      let mtx = new THREE.Matrix4(); //创建一个4维矩阵
      // .lookAt ( eye : Vector3, target : Vector3, up : Vector3 ) : this,构造一个旋转矩阵，从eye 指向 target，由向量 up 定向。
      mtx.lookAt(this.target.position, targetPos, this.target.up); //设置朝向
      mtx.multiply(
        new THREE.Matrix4().makeRotationFromEuler(
          new THREE.Euler(6, offsetAngle, 0)
        )
      );
      let toRot = new THREE.Quaternion().setFromRotationMatrix(mtx); //计算出需要进行旋转的四元数值
      this.target.quaternion.slerp(toRot, 0.2);
      // console.log(this.progress)
    } else if (this.walkTowers === "down") {
      // 转身
      console.log("down");
      if (this.actions[3]) {
        if (this.touchRun) {
          !this.actions[1].isRunning() && this.actions[1].play();
          this.actions[3].isRunning() && this.actions[3].stop();
        } else {
          !this.actions[3].isRunning() && this.actions[3].play();
          this.actions[1].isRunning() && this.actions[1].stop();
        }
        this.actions[0].stop();
      }
      if (this.progress <= 0.014) {
        this.progress = 0.98;
      } else {
        this.progress -= 0.0002;
      }
      const pointBox = this.curve.getPointAt(this.progress - 0.0002 * 20); //获取样条曲线指定点坐标
      const targetPoint = this.curve.getPointAt(this.progress - 0.0002 * 65); //获取样条曲线指定点坐标
      let targetPos = pointBox; //目标位置点
      let offsetAngle = 0; //目标移动时的朝向偏移
      this.camera.position.set(point.x, point.y + 80, point.z);
      this.camera.lookAt(pointBox.x, pointBox.y + 80, pointBox.z);
      this.target.position.set(
        targetPoint.x,
        targetPoint.y - 80,
        targetPoint.z
      );
      this.controls.position0.set(
        targetPoint.x,
        targetPoint.y + 80,
        targetPoint.z
      ); //非必要，场景有控件时才加上
      this.controls.target.set(
        targetPoint.x,
        targetPoint.y + 80,
        targetPoint.z
      ); //非必要，场景有控件时才加上
      // //以下代码在多段路径时可重复执行
      let mtx = new THREE.Matrix4(); //创建一个4维矩阵
      // .lookAt ( eye : Vector3, target : Vector3, up : Vector3 ) : this,构造一个旋转矩阵，从eye 指向 target，由向量 up 定向。
      mtx.lookAt(this.target.position, targetPos, this.target.up); //设置朝向
      mtx.multiply(
        new THREE.Matrix4().makeRotationFromEuler(
          new THREE.Euler(6, offsetAngle, 0)
        )
      );
      let toRot = new THREE.Quaternion().setFromRotationMatrix(mtx); //计算出需要进行旋转的四元数值
      this.target.quaternion.slerp(toRot, 0.2);
      // console.log(this.progress)
    } else if (this.walkTowers === "in") {
      // let angel = Math.PI / 2
      // // let boxMaxY = new THREE.Box3().setFromObject(this.target).max.y
      // let distance = 100
      // 内侧
      // let position = {
      //   x: this.target.position.x + Math.sin(angel) * distance,
      //   y: this.target.position.y + 110,
      //   z: this.target.position.z + Math.sin(angel) * distance
      // }
      // const pointBox = this.curve.getPointAt(this.progress + 0.0002 * 20) //获取样条曲线指定点坐标
      // const targetPoint = this.curve.getPointAt(this.progress + 0.0002 * 65) //获取样条曲线指定点坐标
      let position = {
        x: this.target.position.x - 120,
        y: this.target.position.y + 125,
        z: this.target.position.z - 120,
      };
      let tween = new TWEEN.Tween(this.camera.position).to(position, 1000);
      tween.start();
      // this.target.rotateY(0.1)
      // let tween = new TWEEN.Tween(this.camera.position).to({
      //   x: 0,
      //   y: this.target.position.y + 125,
      //   z: 0
      // }, 1000)
      // tween.start()
    } else if (this.walkTowers === "out") {
      // 外侧
      let tween = new TWEEN.Tween(this.camera.position).to(
        {
          x: this.target.position.x + 120,
          y: this.target.position.y + 125,
          z: this.target.position.z + 120,
        },
        1000
      );

      tween.start();
    } else {
      this.actions[0] && !this.actions[0].isRunning() && this.actions[0].play();
      this.actions[3] && this.actions[3].isRunning() && this.actions[3].stop();
      this.actions[1] && this.actions[1].isRunning() && this.actions[1].stop();
    }
    // if (this.actions[3]) {
    //   if (this.touchRun) {
    //     !this.actions[1].isRunning() && this.actions[1].play();
    //     this.actions[3].isRunning() && this.actions[3].stop();
    //   } else {
    //     // console.log(this.actions[3].isRunning())
    //     !this.actions[3].isRunning() && this.actions[3].play();
    //     this.actions[1].isRunning() && this.actions[1].stop();
    //   }
    //   this.actions[0].stop();
    // }
    // } else {
    //   this.actions[0] && !this.actions[0].isRunning() && this.actions[0].play();
    //   this.actions[3] && this.actions[3].isRunning() && this.actions[3].stop();
    //   this.actions[1] && this.actions[1].isRunning() && this.actions[1].stop();
    // }
    // let time = this.clock.getDelta();
    if (this.mixer) {
      this.mixer.update(this.clock.getDelta());
    }
    // if (this.logoMixer) {
    //   this.logoMixer.update(this.clock.getDelta());
    // }
    // // if  (this.setup.forward === 0) { return }
    // let cloneTarget = this.target.clone();
    // cloneTarget.position.y += this.offestY;
    // // this.labelContents.length > 0 &&
    // //   this.labelContents[0].position.set(
    // //     this.target.position.x,
    // //     this.target.position.y + 0,
    // //     this.target.position.z
    // //   );
    // // this.camera.position.lerp(
    // //   this.followCamera.getWorldPosition(new THREE.Vector3()),
    // //   1
    // // );
    // // console.log(this.camera.position)
    // // this.camera.position.z = cloneTarget.position.z - 300;
    // if (this.manPointLight) {
    //   this.manPointLight.position = this.target.position;
    // }
    // this.camera.lookAt(cloneTarget.position);
    // if (this.setup.forward != 0) {
    //     let direction = cloneTarget.position.sub(this.camera.position).normalize()
    //     this.raycaster.set(this.camera.position, direction );
    //     //   raycaster.far = 210;
    //       let intersects = this.raycaster.intersectObjects([this.room])
    //       if(intersects.length>0){
    //           //加入碰撞检测之后需要做的操作
    //       }
    // }
    // console.log(1 - 0.0002 * 20, { progress: this.progress })
  }
};
// current1 相机当前的位置
// target1 相机的目标位置
// current2 当前的controls的target
// target2 新的controls的target

ModelLoader.prototype.animateCamera = function (
  current1,
  target1,
  current2,
  target2
) {
  var tween = new TWEEN.Tween({
    x1: current1.x, // 相机当前位置x
    y1: current1.y, // 相机当前位置y
    z1: current1.z, // 相机当前位置z
    x2: current2.x, // 控制当前的中心点x
    y2: current2.y, // 控制当前的中心点y
    z2: current2.z, // 控制当前的中心点z
  });
  tween.to(
    {
      x1: target1.x, // 新的相机位置x
      y1: target1.y, // 新的相机位置y
      z1: target1.z, // 新的相机位置z
      x2: target2.x, // 新的控制中心点位置x
      y2: target2.y, // 新的控制中心点位置x
      z2: target2.z, // 新的控制中心点位置x
    },
    1000
  );
  tween.onUpdate(function (object) {
    camera.position.x = object.x1;
    camera.position.y = object.y1;
    camera.position.z = object.z1;
    controls.target.x = object.x2;
    controls.target.y = object.y2;
    controls.target.z = object.z2;
    controls.update();
  });
  tween.onComplete(function () {
    controls.enabled = true;
  });
  tween.easing(TWEEN.Easing.Cubic.InOut);
  tween.start();
};

ModelLoader.prototype.resizeDisplayGL = function () {
  this.recalcAspectRatio();
  this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
  this.updateCamera();
};

ModelLoader.prototype.recalcAspectRatio = function () {
  this.aspectRatio =
    this.canvas.clientHeight === 0
      ? 1
      : this.canvas.clientWidth / this.canvas.clientHeight;
};
ModelLoader.prototype.onClickPlene = function (name) {
  let arr = name.split("_");
  if (name && name.includes("PRO") && arr.length > 0) {
    const pid = arr[1];
    // console.log(pid)
    Bus.$bus.$emit("select-product", pid);
    return;
  }
  if (name && name.includes("LOGO") && arr.length > 0) {
    const pid = arr[1];
    let cLogo = LGGO_BORAD.find((item) => item.id === Number(pid));
    // console.log(cLogo)
    Bus.$bus.$emit("select-logo", { id: pid, url: cLogo ? cLogo.image : "" });
    return;
  }
};
ModelLoader.prototype.resetCamera = function () {
  // this.camera.position.copy(this.cameraDefaults.posCamera);
  // this.cameraTarget.copy(this.cameraDefaults.posCameraTarget);
  this.updateCamera();
};

ModelLoader.prototype.updateCamera = function () {
  this.camera.aspect = this.aspectRatio;
  this.camera.lookAt(this.cameraTarget);
  this.camera.updateProjectionMatrix();
};
/**
 * 渲染器
 */
ModelLoader.prototype.render = function () {
  this.controls && this.controls.update(new THREE.Clock().getDelta());
  this.updateGlobalCamera();
  // this.updateDrive();
  TWEEN.update();
  this.labelRenderer && this.labelRenderer.render(this.scene, this.camera); //渲染HTML标签对象
  this.labelCSS3Render && this.labelCSS3Render.render(this.scene, this.camera); //渲染展画标签对象
  this.renderer.render(this.scene, this.camera);
};
/**
 * 清除模型，模型中有 group 和 scene,需要进行判断
 * @param scene
 * @returns
 */
ModelLoader.prototype.clearScene = function () {
  if (this.scene !== null && this.scene.children.length > 3) {
    this.scene.children.pop();
    // 必须要清空当前div下的canvas不然canvas会继续叠加为什么呢？明明场景和相机等等都清空了
    let domDiv = document.getElementById("container");
    if (domDiv !== null) {
      domDiv.removeChild(domDiv.firstChild);
    }
    this.labelRenderer.domElement &&
      document.body.removeChild(this.labelRenderer.domElement);
    if (this.meshList.length > 0) {
      for (let i = 0; i < this.meshList.length; i++) {
        let currObj = this.meshList[i];
        // 判断类型
        if (currObj instanceof THREE.Scene) {
          let children = currObj.children;
          for (let i = 0; i < children.length; i++) {
            this.deleteGroup(children[i]);
          }
        } else {
          this.deleteGroup(currObj);
        }
      }
      this.scene = null;
      this.camera = null;
      this.renderer = null;
      this.controls = null;
    }
  }
};

// 删除group，释放内存
ModelLoader.prototype.deleteGroup = function (group) {
  if (!group) return;
  // 删除掉所有的模型组内的mesh
  group.traverse(function (item) {
    // console.log(item.name)
    if (item instanceof THREE.Mesh) {
      item.geometry.dispose(); // 删除几何体
      item.material.dispose(); // 删除材质
    }
  });
};

export default ModelLoader;
