<template>
  <div v-if="!isClose" class="goods-content">
    <div class="df">
      <div class="left-goods-content">
        <div class="df">
          <div class="goods-bg">
            <img
              class="view-img"
              :src="goodsList[activeIndex]"
              @click.stop="swiperVisible = true"
            />
          </div>
          <div class="goods-intro">
            <p
              v-for="(item, index) in goodsList"
              :key="index"
              class="mb10 center"
            >
              <img
                :class="index === activeIndex ? 'bs1-FF3400' : 'bs1-fff'"
                class="cp mwp100"
                :src="item"
                @click.stop="hadleClickEvent(index)"
              />
            </p>
          </div>
        </div>
        <div class="left-operate left mt10">
        </div>
      </div>
      <div class="right-goods-content left">
        <i
          class="iconfont icon-close color-fff fs24 cp"
          @click.stop="handleChangeEvent('close')"
        ></i>
        <div class="height-common">
          <div class="title color-fff fs16 fw600">
            {{ productPrice.store_name }}
          </div>
          <div v-if="isBuy">
            <div class="fs16 fw600 color-fff mt16">订单编号：DT00000025</div>
            <div class="intro-detail-content color-DFDFDF">
              <div class="mt8">
                <i class="iconfont icon-daohang fs16 vam mr4"></i>
                <span class="vam fs14">{{ localAddress.address }}</span>
              </div>
              <div class="mt8">
                <i class="iconfont icon-24gl-phoneLoudspeaker fs16 vam mr4"></i>
                <span class="vam fs14">{{ localAddress.tel }}</span>
              </div>
              <div class="mt8">
                <i class="iconfont icon-yonghu fs18 vam mr4"></i>
                <span class="vam fs14">{{ localAddress.name }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="collect color-fff">
              <span class="fs14 mr4">作者</span>
              <span class="fs12">{{productPrice.author}}</span>
            </div>
            <div
              class="intro-detail-content fs12 color-DFDFDF"
              v-html="productPrice.description"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 展画预览 -->
    <photo-swiper
      @close="handleClose"
      :visible="swiperVisible"
      :images="images"
      loop
    ></photo-swiper>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "NameComponent",
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    goods: {
      type: Array,
      default: () => {
        return [];
      },
    },
    productPrice: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isClose: true,
      isBuy: false,
      activeIndex: 0,
      localAddress: {
        name: "---",
        tel: "---",
        address: "---",
      },
      goodsList: [],
      swiperVisible: false,
      images: [],
    };
  },
  computed: {
    ...mapState(["selectedAddress"]),
  },
  watch: {
    selectedAddress: {
      handler(val) {
        if (val) {
          if (JSON.stringify(val) === "{}") {
            this.localAddress = { name: "---", tel: "---", address: "---" };
          } else if (Object.keys(val)) {
            this.localAddress = { ...val };
          } else {
            val = JSON.parse(val);
            this.localAddress = { ...val };
          }
        }
      },
      immediate: true,
    },
    goods: {
      handler(val) {
        this.goodsList = val;
        this.images = val.map((item) => {
          return {
            src: item,
            w: 500,
            h: 500
          };
        });
      },
      immediate: true,
      deep: true,
    },
    isClose (val) {
      if (val) {
        // console.log('关闭了')
        this.$emit('close-goods')
      }
    }
  },
  async created() {
    // this.initProductDetail()
  },
  methods: {
    ...mapActions(["getProductsDetail"]),
    async initProductDetail() {
      const data = await this.getProductsDetail({ id: 4 });
      console.log(data);
      this.productPrice.price = data.price;
      this.productPrice.sales = data.sales;
      this.productPrice.stock = data.stock;
      this.productPrice.store_name = data.store_name;
      this.productPrice.description = data.description;
      const imgList = [];
      if (
        data.slider_image &&
        Array.isArray(data.slider_image) &&
        data.slider_image.length
      ) {
        data.slider_image.map((item) => {
          const json = {
            normalUrl: item,
            bigUrl: item,
          };
          imgList.push(json);
        });
      }
      this.goodsList = imgList;
    },
    hadleClickEvent(index) {
      this.activeIndex = index;
    },
    handleCloseEvent() {
      // this.isClose = true
      // this.isBuy = false
    },
    handleChangeEvent(flag) {
      if (typeof this.params.handleChangeEvent === "function") {
        this.params.handleChangeEvent(flag);
      }
    },
    handleBuyEvent() {
      this.isBuy = true;
    },
    handleClose() {
      this.swiperVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-content {
  position: fixed;
  z-index: 999;
  width: 640px;
  height: 320px;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  left: calc(50% - 320px);
  top: calc(50% - 110px);
  // top: calc(50% - 145px);
  .left-operate {
    // display: flex;
    // justify-content: space-between;
  }
  .goods-btn {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border: solid 1px #707070;
    padding: 0px 6px;
  }
  .auth {
    width: 20px;
  }
  .left-goods-content {
    background-color: #3b466d;
    flex: 0 0 310px;
    opacity: 0.95;
    padding: 16px 10px;
    margin-right: 5px;
    .goods-bg {
      position: relative;
      background-color: #273051;
      height: 210px;
      width: 240px;
      .tag-img {
        width: 45px;
        height: 45px;
        position: absolute;
        margin-left: -10px;
      }
      .view-img {
        width: 220px;
        height: 177px;
        margin-top: 16px;
      }
    }
    .goods-intro {
      width: 80px;
      padding-left: 4px;
      overflow: auto;
      height: 210px;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
      img {
        max-width: 60px;
        height: 36px;
      }
    }
  }
  .right-goods-content {
    background-color: #3b466d;
    opacity: 0.95;
    flex: 0 0 300px;
    padding: 16px 10px 16px 10px;
    .height-common {
      height: 210px;
      padding-top: 10px;
    }
    .intro-detail-content {
      height: 114px;
      margin-top: 20px;
      padding: 10px 0px;
      line-height: 24px;
      overflow: auto;
      border-top: solid 1px #cccccc;
      border-bottom: solid 1px #cccccc;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
    }
    .icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .collect {
      margin-top: 10px;
    }
    .collect-btn {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      color: #333;
      padding: 0px 6px;
      border-radius: 12px;
      margin-top: 8px;
    }
    .title {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
  .author {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #3b466d;
    color: #fff;
    font-size: 12px;
    padding: 4px 5px;
  }
}
</style>
