<template>
  <div>
    <div class="mormal-axis ma"></div>
    <div class="active-axis-parent ma">
      <div class="active-axis" :style="{ width: params.persent + '%', backgroundColor: params.color }"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loading',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      height: '',
      width: '',
    }
  },
  methods: {
    setLoadingEvent() {},
  },
}
</script>
<style lang="scss" scoped>
.mormal-axis {
  border-radius: 5px;
  height: 10px;
  width: 203px;
  background-color: #fff;
}
.active-axis-parent {
  width: 203px;
}
.active-axis {
  position: relative;
  margin-top: -10px;
  border-radius: 5px;
  height: 10px;
  background-color: #ff3400;
}
</style>
