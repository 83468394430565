import "@/assets/scss/reset.scss";
import "@/assets/scss/common.scss";
import "amfe-flexible";

import App from "./App.vue";
import { NewToast } from "./components/Toast/index.js";
import Vue from "vue";
import router from "./router";
import store from "./store";
import Bus from "@/util/bus.js";
import 'plugin-photo-swiper/lib/photo-swiper.css'
import PhotoSwiper from 'plugin-photo-swiper'


Vue.use(NewToast);
Vue.use(PhotoSwiper);
Vue.config.productionTip = false;

Vue.prototype.$bus = Bus;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
