<template>
  <div v-if="!isClose" class="address-content">
    <div class="right icon-close-content">
      <i class="iconfont icon-close color-fff fs24 cp" @click="handleClose"></i>
    </div>
    <div class="color-fff fs16 fw600 left mt10">{{ title }}</div>
    <div class="main-address-content mt10">
      <div v-for="(item, index) in addressList" :key="index" class="main-addres-item ma left color-DFDFDF mb10 fs12">
        <span
          v-if="eventFlag === '2' || item.type === '2'"
          class="btn btnf mr10 cp bs1 fs14 mt10 bg-color-FF3400 bs1-FF3400"
          @click="delEvent(index)"
          >删除</span
        >
        <span
          v-if="eventFlag === '2'"
          class="btn btnf mr10 cp bs1 mt10 fs14 bg-color-FFB300 bs1-FFB300"
          @click="handleChange(item)"
          >修改</span
        >
        <span v-if="eventFlag === '1'" class="btn btnf mr10 cp bs1 mt10" @click="handleSelect(item)">{{
          item.type === '1' ? '选择' : '确认'
        }}</span>
        <div>
          <i class="iconfont icon-yonghu fs24 vam mr4"></i>
          <span v-if="item.type === '1'" class="vam mr24">{{ item.name }}</span>
          <input v-if="item.type === '2'" v-model="item.name" class="vam mr24" />
          <i class="iconfont icon-24gl-phoneLoudspeaker fs24 vam mr4"></i>
          <span v-if="item.type === '1'" class="vam">{{ item.tel }}</span>
          <input v-if="item.type === '2'" v-model="item.tel" class="vam mr24" />
        </div>
        <div class="mt5">
          <i class="iconfont icon-daohang fs24 vam mr4"></i>
          <span v-if="item.type === '1'" class="vam">{{ item.address }}</span>
          <input v-if="item.type === '2'" v-model="item.address" class="vam mr50 w478" />
        </div>
      </div>
    </div>
    <div class="bottom-content right color-fff mt10 fs16">
      <span class="btn mr10 cp bs1 fs14" @click="handleAddEvent('1')">新增地址</span>
      <span class="btn bg-color-169F7F fs14 cp bs1-169F7F" @click="handleAddEvent('2')">管理地址</span>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'AddressComponent',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: '地址列表：',
      eventFlag: '1',
      isClose: true,
      addressList: [
        {
          name: '李先生1',
          tel: '13333333333',
          address: '广东省深圳市南山区深南大道111号荔园大厦',
          type: '1',
        },
        {
          name: '李先生2',
          tel: '13333333333',
          address: '广东省深圳市南山区深南大道111号荔园大厦',
          type: '1',
        },
        {
          name: '李先生3',
          tel: '13333333333',
          address: '广东省深圳市南山区深南大道111号荔园大厦',
          type: '1',
        },
        {
          name: '李先生4',
          tel: '13333333333',
          address: '广东省深圳市南山区深南大道111号荔园大厦',
          type: '1',
        },
      ],
    }
  },
  methods: {
    ...mapMutations(['setSelectAddress']),
    handleAddEvent(flag) {
      this.eventFlag = flag
      if (flag === '1') {
        console.log(this.findAddIndex())
        this.findAddIndex() < 0 &&
          this.addressList.unshift({
            name: '',
            tel: '',
            address: '',
            type: '2',
          })
      }
    },
    findAddIndex() {
      let index = -1
      if (this.addressList.length) {
        index = this.addressList.findIndex((item) => item.type === '2')
      }
      return index
    },
    delEvent(index) {
      this.addressList.splice(index, 1)
    },
    handleChange(item) {
      this.eventFlag = '1'
      item.type = '2'
    },
    handleSelect(item) {
      if (item.type === '2') {
        if (!item.name) {
          this.$message.warning('请输入姓名')
          return
        }
        if (!item.tel) {
          this.$message.warning('请输入手机号')
          return
        }
        if (!item.address) {
          this.$message.warning('请输入地址')
          return
        }
        item.type = '1'
        return
      }
      this.setSelectAddress(item)
      if (typeof this.params.handleAddressClickEvent === 'function') {
        this.params.handleAddressClickEvent('selectAddress')
      }
    },
    handleClose() {
      this.isClose = true
      if (typeof this.params.handleAddressClickEvent === 'function') {
        this.params.handleAddressClickEvent('close')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.address-content {
  position: fixed;
  z-index: 999;
  width: 640px;
  padding: 16px;
  height: 320px;
  background-color: #3b466d;
  left: calc(50% - 320px);
  top: calc(50% - 160px);
  .main-address-content {
    height: 210px;
    overflow: auto;
    input {
      border: none;
      outline: none;
      width: 150px;
      padding: 2px 4px;
    }
    &::-webkit-scrollbar {
      display: none; /*隐藏滚动条*/
    }
    .main-addres-item {
      padding: 8px 10px;
      background: #535e85;
      border-radius: 5px;
      opacity: 1;
      border: 1px solid #7b7b7b;
    }
  }
  .btn {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border: solid 1px #fff;
    padding: 0px 10px;
  }
  .btnf {
    float: right;
  }
  .icon-close-content {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}
</style>
