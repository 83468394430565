<template>
  <div class="context" v-show="isshow">
    <span class="tip">{{ text }}</span>
  </div>
</template>
<script>
import { duration } from '@/conf/config'
console.log(duration)
export default {
  name: 'Toast',
  props: {
    isshow: {
      type: Boolean,
    },
    text: {
      type: String,
    },
  },
  watch: {
    isshow(val) {
      console.log(val)
      if (val === true) {
        setTimeout(() => {
          this.isshow = false
        }, duration)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.context {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .tip {
    background-color: rgba(40, 40, 40, 0.8);
    color: aliceblue;
    font-size: 16px;
    padding: 20px;
    border-radius: 10px;
  }
}
</style>
