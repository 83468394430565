export const ART_BOARD = [
  {
    id: 1,
    width: 150,
    height: 200,
    length: 2,
    image: "./images/exhibitions/01.jpg",
    position: {
      x: 1356,
      y: 350,
      z: -4366,
      
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0
    }
  },
  {
    id: 2,
    width: 150,
    height: 200,
    length: 2,
    image: "./images/exhibitions/01.jpg",
    position: {
      x: 1619,
      y: 350,
      z: -4365,
    },
    rotation: {
      x: 0,
      y: -0.1,
      z: 0
    }
  },
  // {
  //   id: 3,
  //   width: 150,
  //   height: 200,
  //   length: 2,
  //   image: "./images/exhibitions/01.jpg",
  //   position: {
  //     x: 1767,
  //     y: 350,
  //     z: -4308,
  //   },
  //   rotation: {
  //     x: 0,
  //     y: -0.1,
  //     z: 0
  //   }
  // },
];

// logo墙
export const LGGO_BORAD = [
  {
    id: 1,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/万科.png",
    position: {
      // x: 4225,
      // y: 520,
      // z: 690,
      x: 2970,
      y: 520,
      z: -350,
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 11,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/oppo.png",
    position: {
      x: 2970,
      y: 410,
      z: -350
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 111,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/上海博物馆.png",
    position: {
      x: 2970,
      y: 300,
      z: -350
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 1111,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/7天酒店.png",
    position: {
      x: 2970,
      y: 190,
      z: -350
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 2,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/ITID信投公司.png",
    position: {
      x: 2970,
      y: 520,
      z: -200
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 22,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/阿里拍卖.png",
    position: {
      x: 2970,
      y: 410,
      z: -200
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 222,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/铂涛集团.png",
    position: {
      x: 2970,
      y: 300,
      z: -200
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 2222,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/成都熊猫基地.png",
    position: {
      x: 2970,
      y: 190,
      z: -200
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 3,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/大悦城.png",
    position: {
      x: 2970,
      y: 520,
      z: -50
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 33,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/荻原美术馆.png",
    position: {
      x: 2970,
      y: 410,
      z: -50
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 333,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/关山月美术馆.png",
    position: {
      x: 2970,
      y: 300,
      z: -50
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 3333,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/海昌眼镜.png",
    position: {
      x: 2970,
      y: 190,
      z: -50
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 4,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/杭州in77.png",
    position: {
      x: 2970,
      y: 520,
      z: 100
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 44,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/河南博物院.png",
    position: {
      x: 2970,
      y: 410,
      z: 100
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 444,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/湖北省博物馆.png",
    position: {
      x: 2970,
      y: 300,
      z: 100
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 4444,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/湖南省博物馆.png",
    position: {
      x: 2970,
      y: 190,
      z: 100
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 5,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/华为主题.png",
    position: {
      x: 2970,
      y: 520,
      z: 250
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 55,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/绘王.png",
    position: {
      x: 2970,
      y: 410,
      z: 250
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 555,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/开心麻花.png",
    position: {
      x: 2970,
      y: 300,
      z: 250
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 5555,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/肯德基.png",
    position: {
      x: 2970,
      y: 190,
      z: 250
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 6,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/丽枫酒店.png",
    position: {
      x: 2970,
      y: 520,
      z: 400
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 66,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/良品铺子.png",
    position: {
      x: 2970,
      y: 410,
      z: 400
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 666,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/美的.png",
    position: {
      x: 2970,
      y: 300,
      z: 400
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 6666,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/陕西历史博物馆.png",
    position: {
      x: 2970,
      y: 190,
      z: 400
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 7,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/山西博物馆.png",
    position: {
      x: 2970,
      y: 520,
      z: 550
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 77,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/欧时力.png",
    position: {
      x: 2970,
      y: 410,
      z: 550
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 777,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/努比亚.png",
    position: {
      x: 2970,
      y: 300,
      z: 550
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 7777,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/名创优品.png",
    position: {
      x: 2970,
      y: 190,
      z: 550
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 8,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/上海地铁.png",
    position: {
      x: 2970,
      y: 520,
      z: 700
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 88,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/上海公共交通卡.png",
    position: {
      x: 2970,
      y: 410,
      z: 700
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 888,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/深圳博物馆.png",
    position: {
      x: 2970,
      y: 300,
      z: 700
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
  {
    id: 8888,
    width: 100,
    height: 100,
    length: 2,
    image: "./images/logo/深圳南山区博物馆.png",
    position: {
      x: 2970,
      y: 190,
      z: 700
    },
    rotation: {
      x: 0,
      y: -Math.PI / 2,
      z: 0
    }
  },
]
export const LOGO_BOARDS_2 = [
{
  id: 9,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/时尚深圳展.png",
  position: {
    x: 2970,
    y: 520,
    z: 510
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 99,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/世茂集团.png",
  position: {
    x: 2970,
    y: 410,
    z: 510
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 999,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/视觉中国.png",
  position: {
    x: 2970,
    y: 300,
    z: 510
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 9999,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/首都博物馆.png",
  position: {
    x: 2970,
    y: 190,
    z: 510
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 10,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/腾讯qq.png",
  position: {
    x: 2970,
    y: 520,
    z: 660
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 1010,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/万象城.png",
  position: {
    x: 2970,
    y: 410,
    z: 660
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 101010,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/网易lofter.png",
  position: {
    x: 2970,
    y: 300,
    z: 660
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 10101010,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/微博美学.png",
  position: {
    x: 2970,
    y: 190,
    z: 660
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 11,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/微博艺术.png",
  position: {
    x: 2970,
    y: 520,
    z: 810
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 1111,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/信投物业.png",
  position: {
    x: 2970,
    y: 410,
    z: 810
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 111111,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/星河集团.png",
  position: {
    x: 2970,
    y: 300,
    z: 810
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 11111111,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/雪花.png",
  position: {
    x: 2970,
    y: 190,
    z: 810
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 12,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/永威木色空间.png",
  position: {
    x: 2970,
    y: 520,
    z: 990
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 1212,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/远洋集团.png",
  position: {
    x: 2970,
    y: 410,
    z: 990
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 121212,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/站酷.png",
  position: {
    x: 2970,
    y: 300,
    z: 990
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 12121212,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/张裕.png",
  position: {
    x: 2970,
    y: 190,
    z: 990
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 13,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/浙江省博物馆.png",
  position: {
    x: 2970,
    y: 520,
    z: 1140
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 1313,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/中国版权保护中心.png",
  position: {
    x: 2970,
    y: 410,
    z: 1140
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 131313,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/中国航天.png",
  position: {
    x: 2970,
    y: 300,
    z: 1140
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 13131313,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/中国文物交流中心.png",
  position: {
    x: 2970,
    y: 190,
    z: 1140
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},
{
  id: 14,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/logo/重庆中国三峡博物馆.png",
  position: {
    x: 2970,
    y: 190,
    z: 1290
  },
  rotation: {
    x: 0,
    y: -Math.PI / 2,
    z: 0
  }
},]
// 金奖-外圈-ok
export const GLOD_BORAD_OUT = [{
  id: 5,
  width: 420,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/out/5.jpg",
  position: {
    x: -3480,
    y: 200,
    z: -3885,
  },
  rotation: {
    x: 0,
    y: 0.3,
    z: 0
  }
},{
  id: 6,
  width: 360,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/out/6.png",
  position: {
    x: -3900,
    y: 200,
    z: -3550,
  },
  rotation: {
    x: 0,
    y: 0.35,
    z: 0
  }
},{
  id: 7,
  width: 1200,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/out/7.png",
  position: {
    x: -4500,
    y: 200,
    z: -2900,
  },
  rotation: {
    x: 0,
    y: 0.4,
    z: 0
  }
},{
  id: 8,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/out/8.jpg",
  position: {
    x: -5000,
    y: 200,
    z: -2200,
  },
  rotation: {
    x: 0,
    y: 1.1,
    z: 0
  }
}]
// 金奖-内圈-ok
export const GLOD_BORAD_IN = [{
  id: 1,
  width: 406,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/in/1.png",
  position: {
    x: -2140,
    y: 200,
    z: -3900,
  },
  rotation: {
    x: 0,
    y: 0.45,
    z: 0
  }
},{
  id: 2,
  width: 780,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/in/2.png",
  position: {
    x: -2790,
    y: 200,
    z: -3480,
  },
  rotation: {
    x: 0,
    y: 0.65,
    z: 0
  }
},{
  id: 3,
  width: 295,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/in/3.png",
  position: {
    x: -3910,
    y: 200,
    z: -2350,
  },
  rotation: {
    x: 0,
    y: 1.00,
    z: 0
  }
},{
  id: 4,
  width: 550,
  height: 100,
  length: 2,
  image: "./images/exhibitions/J/in/4.png",
  position: {
    x: -4170,
    y: 200,
    z: -1910,
  },
  rotation: {
    x: 0,
    y: 1.10,
    z: 0
  }
}]
// 评委奖-外圈-ok
export const JUDGE_BORAD_OUT = [{
  id: 51,
  width: 330,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/out/51.jpg",
  position: {
    x: 5340,
    y: 200,
    z: 1440,
  },
  rotation: {
    x: 0,
    y: 1.3,
    z: 0
  }
},{
  id: 52,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/out/52.jpg",
  position: {
    x: 5220,
    y: 200,
    z: 1800,
  },
  rotation: {
    x: 0,
    y: 1.10,
    z: 0
  }
},{
  id: 53,
  width: 240,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/out/53.jpg",
  position: {
    x: 5080,
    y: 200,
    z: 2080,
  },
  rotation: {
    x: 0,
    y: 1.05,
    z: 0
  }
},{
  id: 54,
  width: 290,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/out/54.jpg",
  position: {
    x: 4900,
    y: 200,
    z: 2360,
  },
  rotation: {
    x: 0,
    y: 1.00,
    z: 0
  }
},{
  id: 55,
  width: 420,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/out/55.jpg",
  position: {
    x: 4700,
    y: 200,
    z: 2688,
  },
  rotation: {
    x: 0,
    y: 0.95,
    z: 0
  }
}]
// 评委奖-内圈-ok
export const JUDGE_BORAD_IN = [{
  id: 47,
  width: 330,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/in/47.jpg",
  position: {
    x: 4320,
    y: 200,
    z: 1480,
  },
  rotation: {
    x: 0,
    y: 1.25,
    z: 0
  }
},{
  id: 48,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/in/48.jpg",
  position: {
    x: 4200,
    y: 200,
    z: 1800,
  },
  rotation: {
    x: 0,
    y: 1.20,
    z: 0
  }
},{
  id: 49,
  width: 480,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/in/49.jpg",
  position: {
    x: 4050,
    y: 200,
    z: 2130,
  },
  rotation: {
    x: 0,
    y: 1.10,
    z: 0
  }
},{
  id: 50,
  width: 480,
  height: 100,
  length: 2,
  image: "./images/exhibitions/PW/in/50.jpg",
  position: {
    x: 3750,
    y: 200,
    z: 2000,
  },
  rotation: {
    x: 0,
    y: 0.9,
    z: 0
  }
}]
// 铜奖-外圈
export const COPPER_BORAD_OUT = [{
  id: 36,
  width: 150,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/36.jpg",
  position: {
    x: 1080,
    y: 200,
    z: 5280,
  },
  rotation: {
    x: 0,
    y: 0.3,
    z: 0
  }
},{
  id: 37,
  width: 130,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/37.jpg",
  position: {
    x: 1430,
    y: 200,
    z: 5208,
  },
  rotation: {
    x: 0,
    y: 0.35,
    z: 0
  }
},{
  id: 38,
  width: 380,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/38.jpg",
  position: {
    x: 1820,
    y: 200,
    z: 5080,
  },
  rotation: {
    x: 0,
    y: 0.40,
    z: 0
  }
},{
  id: 39,
  width: 285,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/39.jpg",
  position: {
    x: 2190,
    y: 200,
    z: 4920,
  },
  rotation: {
    x: 0,
    y: 0.45,
    z: 0
  }
},{
  id: 40,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/40.jpg",
  position: {
    x: 2580,
    y: 200,
    z: 4730,
  },
  rotation: {
    x: 0,
    y: 0.50,
    z: 0
  }
},{
  id: 41,
  width: 270,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/41.jpg",
  position: {
    x: 2990,
    y: 200,
    z: 4470,
  },
  rotation: {
    x: 0,
    y: 0.55,
    z: 0
  }
},{
  id: 42,
  width: 240,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/42.jpg",
  position: {
    x: 3350,
    y: 200,
    z: 4220,
  },
  rotation: {
    x: 0,
    y: 0.65,
    z: 0
  }
},{
  id: 43,
  width: 300,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/43.jpg",
  position: {
    x: 3610,
    y: 200,
    z: 3970,
  },
  rotation: {
    x: 0,
    y: 0.75,
    z: 0
  }
},{
  id: 44,
  width: 300,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/44.jpg",
  position: {
    x: 3930,
    y: 200,
    z: 3660,
  },
  rotation: {
    x: 0,
    y: 0.80,
    z: 0
  }
},{
  id: 45,
  width: 500,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/45.jpg",
  position: {
    x: 4220,
    y: 200,
    z: 3270,
  },
  rotation: {
    x: 0,
    y: 0.80,
    z: 0
  }
},{
  id: 46,
  width: 70,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/out/46.jpg",
  position: {
    x: 4440,
    y: 200,
    z: 3020,
  },
  rotation: {
    x: 0,
    y: 0.80,
    z: 0
  }
}]
// 铜奖-内圈
export const COPPER_BORAD_IN = [{
  id: 25,
  width: 140,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/25.jpg",
  position: {
    x: 950,
    y: 200,
    z: 4420,
  },
  rotation: {
    x: 0,
    y: 0.3,
    z: 0
  }
},{
  id: 26,
  width: 460,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/26.jpg",
  position: {
    x: 1410,
    y: 200,
    z: 4300,
  },
  rotation: {
    x: 0,
    y: 0.3,
    z: 0
  }
},{
  id: 27,
  width: 215,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/27.jpg",
  position: {
    x: 1830,
    y: 200,
    z: 4150,
  },
  rotation: {
    x: 0,
    y: 0.33,
    z: 0
  }
},{
  id: 28,
  width: 115,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/28.jpg",
  position: {
    x: 2040,
    y: 200,
    z: 4055,
  },
  rotation: {
    x: 0,
    y: 0.35,
    z: 0
  }
},{
  id: 29,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/29.jpg",
  position: {
    x: 2280,
    y: 200,
    z: 3940,
  },
  rotation: {
    x: 0,
    y: 0.37,
    z: 0
  }
},{
  id: 30,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/30.jpg",
  position: {
    x: 2600,
    y: 200,
    z: 3770,
  },
  rotation: {
    x: 0,
    y: 0.50,
    z: 0
  }
},{
  id: 31,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/31.jpg",
  position: {
    x: 2830,
    y: 200,
    z: 3590,
  },
  rotation: {
    x: 0,
    y: 0.80,
    z: 0
  }
},{
  id: 32,
  width: 130,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/32.jpg",
  position: {
    x: 2980,
    y: 200,
    z: 3420,
  },
  rotation: {
    x: 0,
    y: 0.81,
    z: 0
  }
},{
  id: 33,
  width: 130,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/33.jpg",
  position: {
    x: 3120,
    y: 200,
    z: 3270,
  },
  rotation: {
    x: 0,
    y: 0.84,
    z: 0
  }
},{
  id: 34,
  width: 130,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/34.jpg",
  position: {
    x: 3302,
    y: 200,
    z: 3080,
  },
  rotation: {
    x: 0,
    y: 0.86,
    z: 0
  }
},{
  id: 35,
  width: 145,
  height: 100,
  length: 2,
  image: "./images/exhibitions/T/in/35.jpg",
  position: {
    x: 3420,
    y: 200,
    z: 2930,
  },
  rotation: {
    x: 0,
    y: 0.88,
    z: 0
  }
}]
// 银奖1外圈-ok
export const SLIVER_BORAD_OUT = [{
  id: 18,
  width: 300,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/18.jpg",
  position: {
    x: -5420,
    y: 200,
    z: 1100,
  },
  rotation: {
    x: 0,
    y: -1.5,
    z: 0
  }
},{
  id: 19,
  width: 140,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/19.jpg",
  position: {
    x: -5400,
    y: 200,
    z: 1340,
  },
  rotation: {
    x: 0,
    y: -1.4,
    z: 0
  }
},{
  id: 20,
  width: 1270,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/20.jpg",
  position: {
    x: -5100,
    y: 200,
    z: 2100,
  },
  rotation: {
    x: 0,
    y: -1.2,
    z: 0
  }
},{
  id: 21,
  width: 270,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/21.jpg",
  position: {
    x: -4700,
    y: 200,
    z: 2900,
  },
  rotation: {
    x: 0,
    y: -1.0,
    z: 0
  }
},{
  id: 22,
  width: 500,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/22.jpg",
  position: {
    x: -4400,
    y: 200,
    z: 3300,
  },
  rotation: {
    x: 0,
    y: -0.9,
    z: 0
  }
},{
  id: 23,
  width: 215,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/23.jpg",
  position: {
    x: -4050,
    y: 200,
    z: 3730,
  },
  rotation: {
    x: 0,
    y: -0.8,
    z: 0
  }
},{
  id: 24,
  width: 110,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/out/24.jpg",
  position: {
    x: -3800,
    y: 200,
    z: 3990,
  },
  rotation: {
    x: 0,
    y: -0.7,
    z: 0
  }
}]
// 银奖-内圈-ok
export const SLIVER_BORAD_IN = [{
  id: 9,
  width: 340,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/9.jpg",
  position: {
    x: -4430,
    y: 200,
    z: 960,
  },
  rotation: {
    x: 0,
    y: -1.35,
    z: 0
  }
},{
  id: 10,
  width: 500,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/10.jpg",
  position: {
    x: -4270,
    y: 200,
    z: 1500,
  },
  rotation: {
    x: 0,
    y: -1.25,
    z: 0
  }
},{
  id: 11,
  width: 220,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/11.jpg",
  position: {
    x: -4130,
    y: 200,
    z: 1930,
  },
  rotation: {
    x: 0,
    y: -1.15,
    z: 0
  }
},{
  id: 12,
  width: 320,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/12.jpg",
  position: {
    x: -3950,
    y: 200,
    z: 2290,
  },
  rotation: {
    x: 0,
    y: -1.10,
    z: 0
  }
},{
  id: 13,
  width: 180,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/13.jpg",
  position: {
    x: -3760,
    y: 200,
    z: 2620,
  },
  rotation: {
    x: 0,
    y: -1.05,
    z: 0
  }
},{
  id: 14,
  width: 310,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/14.jpg",
  position: {
    x: -3480,
    y: 200,
    z: 2990,
  },
  rotation: {
    x: 0,
    y: -0.9,
    z: 0
  }
},{
  id: 15,
  width: 100,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/15.jpg",
  position: {
    x: -3317,
    y: 200,
    z: 3180,
  },
  rotation: {
    x: 0,
    y: -0.8,
    z: 0
  }
},{
  id: 16,
  width: 430,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/16.jpg",
  position: {
    x: -3025,
    y: 200,
    z: 3456,
  },
  rotation: {
    x: 0,
    y: -0.7,
    z: 0
  }
},{
  id: 17,
  width: 430,
  height: 100,
  length: 2,
  image: "./images/exhibitions/Y/in/17.jpg",
  position: {
    x: -2535,
    y: 200,
    z: 3823,
  },
  rotation: {
    x: 0,
    y: -0.6,
    z: 0
  }
}]
// 1-CIB8主题奖-文物遇见插画
export const BOARD_IN_1 = [{
  id: 56,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/56.jpg",
},{
  id: 57,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/57.jpg",
},{
  id: 58,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/58.jpg",
},{
  id: 59,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/59.jpg",
},{
  id: 60,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/60.jpg",
},{
  id: 61,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/61.jpg",
},{
  id: 62,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/62.jpg",
},{
  id: 63,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/63.jpg",
},{
  id: 64,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/64.jpg",
},{
  id: 65,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/65.jpg",
},{
  id: 66,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/66.jpg",
},{
  id: 67,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/67.jpg",
},{
  id: 68,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/68.jpg",
},{
  id: 69,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/69.jpg",
},{
  id: 70,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/70.jpg",
},{
  id: 71,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/71.jpg",
},{
  id: 72,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/72.jpg",
},{
  id: 73,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/73.jpg",
},{
  id: 74,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/74.jpg",
},{
  id: 75,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/75.jpg",
},{
  id: 76,
  length: 2,
  image: "./images/exhibitions/1-CIB8主题奖-文物遇见插画/76.jpg",
}]
// 2-CIB8主题奖-万博城市
export const BOARD_IN_2 = {
  start: 77,
  end: 137,
  unit: 'jpg',
  file: './images/exhibitions/2-CIB8主题奖-万博城市/',
  length: 2  
}
// 3-CIB8主题奖-数字艺术元宇宙
export const BOARD_IN_3 = {
  start: 138,
  end: 186,
  unit: 'jpg',
  file: './images/exhibitions/3-CIB8主题奖-数字艺术元宇宙/',
  length: 2  
}
// 4-视觉中国最具商业价值插画师
export const BOARD_IN_4 = {
  start: 187,
  end: 196,
  unit: 'jpg',
  file: './images/exhibitions/4-视觉中国最具商业价值插画师/',
  length: 2  
}
// 5-CIB8主题奖-华为最佳创意奖
export const BOARD_IN_5 = {
  start: 197,
  end: 206,
  unit: 'jpg',
  file: './images/exhibitions/5-CIB8主题奖-华为最佳创意奖/',
  length: 2  
}
// 6-CIB8主题奖-微博美学人气奖
export const BOARD_IN_6 = {
  start: 207,
  end: 216,
  unit: 'jpg',
  file: './images/exhibitions/6-CIB8主题奖-微博美学人气奖/',
  length: 2  
}
// 7-CIB8主题奖-网易LOFTER人气奖
export const BOARD_IN_7 = {
  start: 217,
  end: 226,
  unit: 'jpg',
  file: './images/exhibitions/7-CIB8主题奖-网易LOFTER人气奖/',
  length: 2  
}
// 8-CIB8主题奖-名创优品
export const BOARD_IN_8 = {
  start: 227,
  end: 243,
  unit: 'jpg',
  file: './images/exhibitions/8-CIB8主题奖-名创优品/',
  length: 2  
}
// 9-CIB8主题奖-命题绘本奖
export const BOARD_IN_9 = {
  start: 224,
  end: 251,
  unit: 'jpg',
  file: './images/exhibitions/9-CIB8主题奖-命题绘本奖/',
  length: 2  
}
// 10-CIB8主题奖-原创绘本奖
export const BOARD_IN_10 = {
  start: 252,
  end: 264,
  unit: 'jpg',
  file: './images/exhibitions/10-CIB8主题奖-原创绘本奖/',
  length: 2  
}