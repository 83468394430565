const getStorage = (name) => {
  return window.localStorage.getItem(name)
}
const setStorage = (name, value) => {
  window.localStorage.setItem(name, value)
}
const clearStorage = (name) => {
  window.localStorage.removeItem(name)
}

const getNaturalWidth = (src) => {
  return new Promise((resolve, reject) => {
    var image = new Image()
    image.src = src
    // 加载完成执行
    image.onload = function () {
      // console.log(image.width, image.height)
      resolve({ width: image.width, height: image.height })
    }
  })
}

module.exports = { getStorage, setStorage, clearStorage, getNaturalWidth }
