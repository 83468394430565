<template>
  <div>
    <div v-if="!isClose" class="message-content color-fff fs20 fw600">
      <div class="text">请手机竖向锁定后横置体验</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message',
  data() {
    return {
      isClose: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.message-content {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1005;
  background-color: rgba(0, 0, 0, 1);
  .text {
    position: absolute;
    width: 400px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 200px);
  }
}
</style>
