<template>
  <div v-if="!isClose">
    <div class="left-title-content left">
      <img
        v-if="params.pageType === '1'"
        class="logo-icon cp"
        src="../assets/img/logo.png"
        @click="handleClickEvent('return')"
      />
      <img
        v-if="params.pageType === '2'"
        class="return-icon cp"
        src="../assets/img/return.png"
        @click="handleClickEvent('return')"
      />
    </div>
    <div class="right-platform-content">
      <div class="df">
        <div class="fl2">
          <div class="df mr50">
            <div class="flex1">
              <div
                :class="['model', 'mr10', daySrc === 1 ? 'day-light' : 'day']"
                @click="handleClickEvent('dayTime')"
              ></div>
            </div>
            <div class="flex1">
              <div
                :class="['model', nightSrc === 1 ? 'night' : 'night-light']"
                @click="handleClickEvent('night')"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="fl3">
          <div class="platform-btn df aic cp" @click="handleClickEvent('shop')">
            <img src="../assets/img/login/shop.png" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "TopTitleComponent",
  props: {
    params: {
      type: Object,
      default: () => ({
        pageType: "1",
      }),
    },
  },
  data() {
    return {
      isClose: true,
      daySrc: 2,
      nightSrc: 1,
    };
  },
  created() {
    this.getProductsList();
  },
  methods: {
    ...mapActions(["getProductsList", "getProductsDetail"]),
    handleClickEvent(flag) {
      if (flag === "return" && typeof this.params.returnEvent === "function") {
        this.params.returnEvent();
      }
      if (
        flag === "dayTime" &&
        typeof this.params.dayTimeEvent === "function"
      ) {
        this.daySrc = 1;
        this.nightSrc = 2;
        this.params.dayTimeEvent();
      }
      if (flag === "night" && typeof this.params.nightEvent === "function") {
        this.daySrc = 2;
        this.nightSrc = 1;
        this.params.nightEvent();
      }
      if (
        flag === "shop" &&
        typeof this.params.shopPlateformEvent === "function"
      ) {
        // this.params.shopPlateformEvent()
        window.location.href = "http://117.80.233.111:9000/zh-s/";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.left-title-content {
  position: fixed;
  z-index: 999;
  width: 120px;
  left: 30px;
  top: 10px;
  .return-icon {
    width: 36px;
    height: 15px;
    margin-top: 12px;
  }
  .logo-icon {
    width: 132px;
    // height: 25px;
  }
}
.right-platform-content {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 10px;
  padding: 0px;
  .model {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-size: contain;
  }
  .day {
    background: url("../assets/img/dayTime.png") no-repeat;
    background-size: contain;
  }
  .day-light {
    background: url("../assets/img/light/daytime.png") no-repeat;
    background-size: contain;
  }
  .night {
    background: url("../assets/img/night.png") no-repeat;
    background-size: contain;
  }
  .night-light {
    background: url("../assets/img/light/night.png") no-repeat;
    background-size: contain;
  }
  .platform-btn {
    height: 35px;
    padding: 5px;
    background: #169f7f;
    border-radius: 3px;
    .shop-icon {
      width: 15px;
      height: 15px;
      margin-right: 2px;
    }
    img {
      width: 100px;
    }
  }
}
</style>
