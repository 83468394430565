import { getCategoryList, getProductsDetail, getProductsList } from '@/api'
import { getStorage, setStorage } from '@/util'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedAddress: getStorage('address') || {},
  },
  mutations: {
    setSelectAddress(state, data) {
      state.selectedAddress = data
      console.log(data)
      setStorage('address', JSON.stringify(data))
    },
  },
  actions: {
    async getCategoryList({}, params) {
      const result = await getCategoryList(params)
      if (result.status * 1 === 200 && result.data && Array.isArray(result.data)) {
        return result.data
      } else {
        return []
      }
    },
    // 获取商品详情
    async getProductsDetail({}, params) {
      let data = {}
      const result = await getProductsDetail(params)
      if (result && result.status * 1 === 200) {
        if (result.data) {
          data = result.data
        }
      }
      return data
    },

    async getProductsList({}, params) {
      const result = await getProductsList(params)
      if (result.status * 1 === 200 && result.data && Array.isArray(result.data)) {
        return result.data
      } else {
        return []
      }
    },
  },
  modules: {},
})
