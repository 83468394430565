import { apiServer, bodyUrl, dataCode, timeOut, tokenName } from '@/conf/config'
import { clearStorage, getStorage } from '@/util/index.js'

import { NewToast } from '@/components/Toast/index.js'
import Vue from 'vue'
/*
 * @Author: lizhanchao
 * @Date: 2020-12-01 11:37:13
 * @LastEditors: lizhanchao
 * @LastEditTime: 2021-08-11 21:56:47
 * @Description: axios
 */
import axios from 'axios'
import qs from 'qs'

Vue.use(NewToast)

// axios 配置
// 默认超时时间
axios.defaults.timeout = timeOut
// post请求数据格式
axios.defaults.headers.post['Content-Type'] = apiServer.contentType
// 设置认证信息
axios.defaults.headers[tokenName] = getStorage(tokenName) || ''
// 数据接口域名统一配置
axios.defaults.baseURL = apiServer[process.env.NODE_ENV || 'development']
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // if (!config.headers[tokenName]) {
    //   if (config.url.indexOf('/quickiam/user/abclogin') < 0) {
    //     alert({
    //       title: '提示',
    //       message: '授权过期，即将重新授权',
    //     }).then(() => {
    //       // abcRedirect()
    //     })
    //   }
    // } else {
    //   const currentTime = parseInt(new Date().getTime() * 0.001) * 1
    //   const decodeTokenData = decodeToken(config.headers.authorization)
    //   if (decodeTokenData.exp < currentTime && config.url.indexOf('/quickiam/user/abclogin') < 0) {
    //     clearStorage(tokenName)
    //     alert({
    //       title: '提示',
    //       message: '授权过期，即将重新授权',
    //     }).then(() => {
    //       // abcRedirect()
    //     })
    //   }
    // }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    console.log(response)
    if (response.data) {
      if (response.data.status === dataCode.invalidCode) {
        clearStorage(tokenName)
        Vue.prototype.$Toast('授权过期，即将重新授权')
        // alert({
        //   title: '提示',
        //   message: '授权过期，即将重新授权',
        // }).then(() => {
        //   // abcRedirect()
        // })
      } else if (response.data.detail) {
        Vue.prototype.$Toast(response.data.detail)
        // alert({ type: 'danger', message: response.data.detail })
      }
    }
    return response
  },
  (error) => {
    let data = null
    console.log(error)
    if (error.response) {
      data = error.response.data
      if (!error.response.config.url.includes('quickpay/open/getrechargeplan')) {
        // alert({ type: 'danger', message: data.detail })
        Vue.prototype.$Toast(data.detail)
      }
    } else {
      // alert({ type: 'danger', message: '网络错误' })
      Vue.prototype.$Toast('网络错误')
    }
    return Promise.reject(data || error)
  }
)

/**
 * get 请求方法
 * @param {*} url
 * @param {*} params
 */
export const get = (url, params, paramsUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .get(paramsUrl ? url + paramsUrl : url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * post 请求方法，发送数据格式 json
 * @param {*} url
 * @param {*} data
 * @param {*} params
 */
export const post = (url, data, params) => {
  return new Promise((resolve, reject) => {
    let body = data
    let isBody = false
    bodyUrl.map((item) => {
      if (url.includes(item)) {
        isBody = true
      }
    })
    if (isBody) {
      body = data
    } else {
      body = qs.stringify(data)
    }
    axios
      .post(url, body, { params })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
