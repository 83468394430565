<template>
  <div v-if="!isClose">
    <div class="rim-main-content">
      <div class="fl left-thumbnail-content color-fff">
        <div class="name left fs14">《今日入眠今日入眠今日入眠》</div>
        <div class="title left fw600 fs18 mt5">相关周边</div>
        <div class="thumbnail-list mt16">
          <p v-for="(item, index) in thumbnailList" :key="index + 'img'">
            <img
              @click="handleSelectImg(index)"
              class="thumbnail-img cp"
              :class="index === activeIndex ? 'bs1-FF3400' : ''"
              :src="item.imgUrl"
            />
          </p>
        </div>
      </div>
      <div class="fl center-detail-content color-fff">
        <div class="name left fs14">
          纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤纯棉印花T恤
        </div>
        <div class="title left fw600 fs18 mt5">图文介绍</div>
        <div class="detail-intro-content center mt16">
          <img class="detail-intro-img" :src="thumbnailList[activeIndex].detailImgUrl" />
        </div>
      </div>
      <div class="fl right-order-content">
        <div><i class="iconfont icon-close color-fff fs24 cp" @click="handleCloseEvent"></i></div>
        <div class="specification-content">
          <p class="title fs14 color-fff left">规格：</p>
          <div class="df specification-wrap">
            <div class="specification-item fs14 bg-color-fff center fl1">S</div>
            <div class="specification-item fs14 bg-color-fff center fl1">M</div>
            <div class="specification-item fs14 bg-color-fff center fl1">L</div>
            <div class="specification-item fs14 bg-color-fff center fl1">S</div>
            <div class="specification-item fs14 bg-color-fff center fl1">M</div>
            <div class="specification-item fs14 bg-color-fff center fl1">L</div>
          </div>
        </div>
        <div class="repertory-content color-fff left">
          <span class="fs14 vam">库存：</span><span class="fs14 vam fw600">25件</span>
        </div>
        <div class="price-content left">
          <span class="fs14 color-fff">价格：</span><span class="fs14 price-btn fw600">&yen; 1200/件</span>
        </div>
        <div class="bottom-buy-content left">
          <i class="iconfont icon-youjiantou1 vam fs16 cp color-fff mr10" @click="handleBuyCountEvent('0')"></i>
          <span class="buy-num vam center fs20 color-fff mr10">{{ buyCount }}</span>
          <i class="iconfont vam icon-youjiantou fs16 cp color-fff" @click="handleBuyCountEvent('1')"></i>
          <span class="cp buy-btn fs14 color-fff vam">立即购买</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RimGoods',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      buyCount: 1,
      isClose: true,
      activeIndex: 0,
      thumbnailList: [
        {
          imgUrl: require('../assets/img/thumbnail1.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
        {
          imgUrl: require('../assets/img/thumbnail2.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
        {
          imgUrl: require('../assets/img/thumbnail1.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
        {
          imgUrl: require('../assets/img/thumbnail2.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
        {
          imgUrl: require('../assets/img/thumbnail1.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
        {
          imgUrl: require('../assets/img/thumbnail2.png'),
          detailImgUrl: require('../assets/img/thumbnail-detail.png'),
        },
      ],
    }
  },
  methods: {
    handleSelectImg(index) {
      this.activeIndex = index
    },
    handleCloseEvent() {
      this.params.handleChangeEvent('close')
    },
    handleBuyCountEvent(flag) {
      if (flag === '0') {
        if (this.buyCount * 1 > 1) {
          this.buyCount -= 1
        }
      } else {
        this.buyCount += 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.rim-main-content {
  width: 640px;
  height: 320px;
  position: fixed;
  z-index: 999;
  left: calc(50% - 320px);
  top: calc(50% - 160px);
  .left-thumbnail-content {
    padding: 16px;
    width: 160px;
    margin-right: 5px;
    background-color: #3b466d;
    opacity: 0.95;
    .thumbnail-list {
      height: 233px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
    }
    .thumbnail-img {
      width: 120px;
      height: 76px;
      border: solid 1px rgba(255, 255, 255, 0);
    }
    .name {
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
  .center-detail-content {
    background-color: #3b466d;
    opacity: 0.95;
    padding: 16px;
    margin-right: 5px;
    .name {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .detail-intro-content {
      height: 233px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
      .detail-intro-img {
        width: 200px;
      }
    }
  }
  .right-order-content {
    background-color: #3b466d;
    opacity: 0.95;
    height: 318px;
    padding: 16px;
    width: 235px;
    .icon-close {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .specification-content {
      margin-top: 30px;
    }
    .specification-wrap {
      // flex-wrap: wrap;
      margin-top: 5px;
    }
    .specification-item {
      padding: 8px;
      border: 1px solid #707070;
      border-radius: 4px;
      margin-right: 10px;
      margin-bottom: 13px;
    }
    .repertory-content {
      padding: 16px 0px;
      border-top: solid 1px #535e85;
      border-bottom: solid 1px #535e85;
    }
    .price-btn {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #ffb300;
      color: #ffb300;
      font-weight: 600;
      padding: 0px 6px;
      background-color: rgba(255, 179, 0, 0.2);
    }
    .price-content {
      margin-top: 12px;
      margin-bottom: 40px;
    }
    .bottom-buy-content {
      .buy-num {
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #535e85;
        border-radius: 5px;
        border: 1px solid #707070;
      }
      .buy-btn {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background: #ffbb00;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 20px;
      }
    }
  }
}
</style>
