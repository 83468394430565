/*
 * @Description: 模块相关API
 */
import { get } from '@/util/axios.js'

/**
 * 获取商品分类列表
 * @param {*} params
 */
const getCategoryList = (params) => get('/category', params)

/**
 * 获取商品列表
 * @param {*} params
 */
const getProductsList = (params) => get('/products', params)
/**
 * 获取商品详情
 * @param {*} params
 */
const getProductsDetail = (params) => get(`/product/detail/${params.id}`, params)
// 获取展画详情
const getProductDetailById = (params) => get(`/product/detail/${params.id}/0/1`)
// 获取logo详情的左侧数据
const getLogoDetailList = (params) => get(`/article/list/${params.id}`)
// 根据logo详情左侧获取详情/article/details/:id
const getLogoDetailInfo = (params) => get(`/article/details/${params.id}`)
// 获取logo列表
const getLogoList = () => get('/article/category/list')

export { getCategoryList, getProductsList, getProductsDetail, getProductDetailById, getLogoDetailList, getLogoDetailInfo, getLogoList }
