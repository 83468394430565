<template>
  <div v-if="!isClose">
    <div class="wall-content">
      <div class="left-content fl bg-color-fff">
        <div class="top-logo-content">
          <img :src="url" />
        </div>
        <div class="bottom-small-content">
          <div
            class="bottom-small-item"
            v-for="(item, index) in leftImgList"
            :key="index + 'img'"
            @click="handleClick(item.id, index)"
          >
            <img :src="item.image_input[0]" />
            <div v-if="activeIndex === index" class="name fs14 color-fff center">{{leftImgList[activeIndex]['title'] || '--'}}</div>
          </div>
        </div>
      </div>
      <div class="right-content fl bg-color-fff">
        <div class="title fs20 fw600 left">
          {{info.title || '--'}}
        </div>
        <div class="introl-detail center" v-html="info.content">
          <!-- <img :src="leftImgList[activeIndex] ? leftImgList[activeIndex].image_input[0] : ''" /> -->
        </div>
      </div>
      <i class="iconfont icon-close color-fff fs24 cp" @click="handleCloseEvent"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VirtualWall',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    url: String,
    info: Object
  },
  data() {
    return {
      isClose: true,
      activeIndex: 0,
      leftImgList: [],
    }
  },
  watch: {
    list: {
      handler (val) {
        this.leftImgList = val
        console.log(this.leftImgList)
      },
      deep: true
    }
  },
  methods: {
    handleClick(id, index) {
      // console.log(index)
      this.activeIndex = index
      this.$emit('get-detail', id)
    },
    handleCloseEvent() {
      this.isClose = true
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.wall-content {
  position: fixed;
  z-index: 999;
  width: 640px;
  height: 320px;
  left: calc(50% - 320px);
  top: calc(50% - 160px);
  .icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #333;
  }
  .left-content {
    width: 160px;
    margin-right: 4px;
    .top-logo-content {
      img {
        width: 160px;
        height: 64px;
      }
    }
    .bottom-small-content {
      height: 250px;
      width: 160px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
      .bottom-small-item {
        width: 135px;
        position: relative;
        height: 86px;
        margin: 0 auto;
        margin-bottom: 4px;
        padding: 0px;
        img {
          width: 135px;
          height: 86px;
        }
        .name {
          position: absolute;
          width: 135px;
          overflow: hidden;
          left: calc(50% - 67.5px);
          bottom: 0px;
          color: #fff;
          background: #ffb300;
          white-space: nowrap;
          padding: 4px;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
    }
  }
  .right-content {
    width: 470px;
    height: 319px;
    .title {
      color: #3b466d;
      margin-top: 16px;
      margin-left: 23px;
      margin-bottom: 17px;
      width: 380px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .introl-detail {
      width: 420px;
      height: 250px;
      margin: 0 auto;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
      img {
        max-width: 590px;
      }
    }
  }
}
</style>
