<template>
  <div v-if="!isClose">
    <div class="index-login-content">
      <div class="df color-fff">
        <div class="fl1 left">
          <img class="logo" src="../assets/img/login/logo2.png" />
        </div>
        <!-- <div class="fl1 right"><img class="shop" src="../assets/img/login/shop.png" /></div> -->
      </div>
      <div class="center-content">
        <!-- <div class="logo2-content">
          <img class="logo2" src="../assets/img/login/logo.png" />
        </div> -->
        <div class="text-content">
          <img class="text" src="../assets/img/login/text.png" />
        </div>
        <LoadingComponent class="mt16" ref="loading" :params="loadingParams"></LoadingComponent>
        <p class="loading">
          loading……
          <!-- 已加载<span>{{ loadingParams.loaded }}</span>/<span>{{ loadingParams.total }}</span> -->
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingComponent from '@/components/LoadingComponent.vue'
export default {
  name: 'IndexDashboard',
  components: {
    LoadingComponent,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isClose: false,
      processTimer: null,
      loadingParams: {
        height: 10,
        width: 200,
        persent: 0,
        color: '#FFA000',
        loaded: 0,
        total: 0
      },
    }
  },
  created() {
    this.startProcessEvent()
    this.$bus.$on('load-model', ({ loaded, total }) => {
      // let loadProcess = Math.floor(loaded / total * 100)
      this.$set(this.loadingParams, 'loaded', loaded)
      this.$set(this.loadingParams, 'total', total)
      // this.$set(this.loadingParams, 'persent', loadProcess)
      // if (loadProcess > 100) {
      //   this.loadingParams.persent = 100
      //   this.params.handleChangeEvent('page')
      //   this.isClose = true
      //   this.clearTimerEvent()
      // }
    })
  },
  // watch: {
  //   loadingParams: {
  //     handler (val) {
  //       if (val.persent >= 100) {
  //         this.params.handleChangeEvent('page')
  //         this.isClose = true
  //         this.clearTimerEvent()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    startProcessEvent() {
      this.clearTimerEvent()
      this.processTimer = setInterval(() => {
        this.loadingParams.persent += 1
        if (this.loadingParams.persent > 100) {
          this.loadingParams.persent = 100
          this.params.handleChangeEvent('page')
          this.isClose = true
          this.clearTimerEvent()
        }
      }, 1 * 50)
    },
    clearTimerEvent() {
      if (this.processTimer) {
        clearInterval(this.processTimer)
        this.processTimer = null
      }
    },
  },
  beforeDestroy() {
    this.clearTimerEvent()
  },
}
</script>
<style lang="scss" scoped>
.index-login-content {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/login/IllusMeta.gif');
  background-size: cover;
  background-repeat: no-repeat;
  .logo {
    width: 132px;
    // height: 40px;
    margin-top: 34px;
    margin-left: 48px;
  }

  .loading {
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    margin-top: 8px;
  }
  .shop {
    width: 130px;
    height: 40px;
    margin-top: 34px;
    margin-right: 53px;
  }
  .center-content {
    position: fixed;
    width: 500px;
    height: 240px;
    z-index: 1008;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    // margin-left: -250px;
    // margin-top: -120px;
    .logo2 {
      // width: 105px;
      // height: 111px;
    }
    .text {
      width: 434px;
      height: 59px;
    }
  }
}
</style>
